import React, { useEffect, useMemo, useState } from "react";
import { createBrowserRouter, RouterProvider, Outlet, Link, useLocation } from "react-router-dom";
import { Image, Layout, Menu, MenuProps, Button } from "antd";
import {
	HomeTabKeyEnum,
	getLanguageList,
	getSeriesData,
	getTagsData,
	getUserData,
	getWholeData,
	titleObj,
} from "./components/utils";
import styled from "@emotion/styled";
import EpisodeComponent from "./components/episode";
import VideosComponent from "./components/videos";
import SeriesComponent from "./components/series";
import ZypeComponent from "./components/zype";
import WurlComponent from "./components/wurl";
import LanguageComponent from "./components/settings/language";
import Metadata from "./components/metadata";
import FileUploaderPageContent from "./components/import/videos";
import TagsComponent from "./components/tags";
import AutoPodderComponent from "./components/auto_podder";
import BatchJobsComponent from "./components/batch_jobs";
import PostLogProcessorComponent from "./components/post_log_processor";
import EPGInputsUploadComponent from "./components/scheduling/epg_inputs_upload";
import SchedulerComponent from "./components/scheduling/scheduler";
import { CaretDownOutlined, PoweroffOutlined } from "@ant-design/icons";
import DistributionPlatformComponent from "./components/distribution_partner";
import Captions from "./components/captions";
import { ConvertedDataType } from "./components/utils/common.interface";
import { SortObj } from "./components/videos/videos";
import {
	authAxios,
	getAPIToken,
	getEmailFromSessionStorage,
} from "./components/utils/session_utils";
import { imageUrls } from "./assets/images";
import CuePointsComponent from "./components/cue-points";
import ThumbnailsComponent from "./components/thumbnails";
import ViewershipUploadComponent from "./components/register/viewership_upload/index";
import PaymentManager from "./components/register/payment_manager/components/main";
import BundleSpotDataUpload from "./components/salesforce/bundle_spot_data";
import AssetsExportComponent from "./components/export/assets";
import AssetsImport from "./components/import/assets";
import WurlExport from "./components/export/platforms/wurl";
import TitleConflictsComponent from "./components/asset-matching";
import VideoListsComponent from "./components/video-lists";
import { getVideoLists } from "./components/video-lists/utils";
import UserSettingsComponent from "./components/settings/user";
import PromosComponent from "./components/promos";
// import ZypeExport from "./components/export/platforms/zype";

const { Header, Content } = Layout;

const StyledHeader = styled(Header)`
	display: flex;
	padding: 0 12px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 100;
	& .ant-menu {
		width: 100%;
		border-left: 1px solid #ccc;
		margin-left: 16px;
	}
`;

const StyledContent = styled(Content)`
	margin-top: 64px;
`;

const NotLoggedInOrDataDiv = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #ddd;
	font-size: 24px;
`;

const MainLabelContainer = styled.div`
	span {
		margin-right: 4px;
	}
`;

function loadSessionStateByKey(key: string, defaultValue: any) {
	const item = window.sessionStorage.getItem(key);
	return item != null ? item : defaultValue;
}

export interface USERDATA {
	id: string;
	email: string;
	name: string;
	picture_url: string;
	email_verified: boolean;
	show_hidden_episodes: boolean;
	show_public_lists: boolean;
	wide_scrollbars: boolean;
	page_sizes: USER_PAGE_SIZE;
}

export interface USER_PAGE_SIZE {
	episodes: number;
	videos: number;
	tags: number;
	captions: number;
	series: number;
	distribution_platform: number;
	metadata: number;
}

export interface Ratings {
	au: string[];
	us: string[];
	canada: string[];
}

const Root = () => {
	const location = useLocation();
	const [languagesList, setLanguagesList] = useState<any[]>([]);
	const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
	const [user, setUser] = useState<USERDATA>();
	const [tagsList, setTagsList] = useState<any[]>([]);
	const [seriesList, setseriesList] = useState<any[]>([]);
	const [distributionPlatformData, setDistributionPlatformData] = useState<ConvertedDataType[]>([]);
	const [distributionPlatformSortObj] = useState<SortObj>({
		column: "name",
		order: "asc",
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [currentUserEmail, setCurrentUserEmail] = useState<string>(
		loadSessionStateByKey("mvmgsid", "")
	);
	const [ratings, setRatings] = useState<Ratings>();
	const [videoLists, setVideoLists] = useState<any[]>([]);

	const [seriesSortObj] = useState<SortObj>({
		column: "updated_at",
		order: "desc",
	});

	// handle scrollbar styling change event
	useEffect(() => {
		const style = document.createElement("style");
		style.setAttribute("id", "custom-scrollbars");
		style.textContent = `
      ::-webkit-scrollbar {
        width: ${8}px;
        height: ${12}px;
      }
    `;
		if (user?.wide_scrollbars) document.head.appendChild(style);
		else {
			const style = document.getElementById("custom-scrollbars");
			if (style) document.head.removeChild(style);
		}

		return () => {
			const style = document.getElementById("custom-scrollbars");
			if (style) document.head.removeChild(style);
		};
	}, [user?.wide_scrollbars]);

	const getRatings = async () => {
		const response = await authAxios({
			method: "GET",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/episode/ratings`,
		});
		setRatings(response.data);
	};

	const initialDataLoading = (userEmail: string) => {
		getWholeData("distribution-platform", setDistributionPlatformData, distributionPlatformSortObj);
		getLanguageList(setLanguagesList);
		getSeriesData(setseriesList, (value: boolean) => value, seriesSortObj);
		getTagsData(setTagsList);
		getUserData(userEmail, setUser);
		getVideoLists(setVideoLists, null, {}, null, { showAllLists: true });
		getRatings();
	};

	useEffect(() => {
		if (location.pathname === "/") {
			window.location.pathname = HomeTabKeyEnum.EPISODE;
		}
		if (window) {
			document.title = location.pathname.toLowerCase().includes("episode")
				? "Episode - Nosey"
				: location.pathname.toLowerCase().includes("video") &&
				  !location.pathname.toLowerCase().includes("import")
				? "Video - Nosey"
				: `${titleObj[location.pathname]} - Nosey`;
		}

		const onOneTapSignedIn = async (response: any) => {
			setIsLoggingIn(true);
			const token = await getAPIToken(response.credential);
			window.sessionStorage.setItem("mvmtoken", token.token);
			const encodedEmail = token?.email;
			setCurrentUserEmail(encodedEmail);
			window.sessionStorage.setItem("mvmgsid", encodedEmail);

			// set user settings and user data
			setUser(token.user);
			// fetch distribution platform, series, language, tags data
			initialDataLoading(encodedEmail);
		};

		window.google.accounts.id.initialize({
			client_id: "667192520117-foq7du5o811hfir7ke8p95alral3dt0e.apps.googleusercontent.com",
			callback: onOneTapSignedIn,
			use_fedcm_for_prompt: true,
		});
		const button_div = document.getElementById("google-signin-div");
		// todo: check/sync if user is already logged in using data-skip_prompt_cookie cookie
		if (currentUserEmail.length === 0) {
			if (button_div) {
				window.google.accounts.id.renderButton(button_div, {
					theme: "outline",
					size: "large",
					text: "signin_with",
					shape: "rectangular",
				});
			}

			// Disabling auto auth-prompt temporarily to test if this is the cause of website blockage with ISPs - VK
			// window.google.accounts.id.prompt();

			// 	if (notification.isNotDisplayed()) {
			// 	} else if (notification.isSkippedMoment()) {
			// 	} else if (notification.isDismissedMoment()) {
			// 	}
			// });
		}
	}, [location.pathname]);

	useMemo(() => {
		const userEmail = getEmailFromSessionStorage();
		if (userEmail) {
			initialDataLoading(userEmail);
		}
	}, []);

	let navigationMenu: MenuProps["items"] = [
		{
			key: "metadata-master",
			label: (
				<MainLabelContainer>
					<span>Metadata</span>
					<CaretDownOutlined />
				</MainLabelContainer>
			),
			children: [
				{
					key: HomeTabKeyEnum.EPISODE,
					label: <Link to={HomeTabKeyEnum.EPISODE}>Episodes</Link>,
				},
				{
					key: HomeTabKeyEnum.VIDEOS,
					label: <Link to={HomeTabKeyEnum.VIDEOS}>Videos</Link>,
				},
				{
					key: HomeTabKeyEnum.SERIES,
					label: <Link to={HomeTabKeyEnum.SERIES}>Series</Link>,
				},
				{
					key: HomeTabKeyEnum.METADATA,
					label: <Link to={HomeTabKeyEnum.METADATA}>Metadata</Link>,
				},
				{
					key: HomeTabKeyEnum.CAPTIONS,
					label: <Link to={HomeTabKeyEnum.CAPTIONS}>Captions</Link>,
				},
				{
					key: HomeTabKeyEnum.CUEPOINTS,
					label: <Link to={HomeTabKeyEnum.CUEPOINTS}>Cue Points</Link>,
				},
				{
					key: HomeTabKeyEnum.THUMBNAILS,
					label: <Link to={HomeTabKeyEnum.THUMBNAILS}>Thumbnails</Link>,
				},
				{
					key: HomeTabKeyEnum.PROMOS,
					label: <Link to={HomeTabKeyEnum.PROMOS}>Promos</Link>,
				},
				{
					key: HomeTabKeyEnum.DISTRIBUTIONR_PLATFORM,
					label: <Link to={HomeTabKeyEnum.DISTRIBUTIONR_PLATFORM}>Distribution Platforms</Link>,
				},
				{
					key: HomeTabKeyEnum.TAGS,
					label: <Link to={HomeTabKeyEnum.TAGS}>Tags</Link>,
				},
				{
					key: HomeTabKeyEnum.ASSET_MATCHING,
					label: <Link to={HomeTabKeyEnum.ASSET_MATCHING}>Asset Matching</Link>,
				},
				{
					key: HomeTabKeyEnum.VIDEO_LISTS,
					label: <Link to={HomeTabKeyEnum.VIDEO_LISTS}>Video Lists</Link>,
				},
				{
					key: "settings",
					label: <Link to={HomeTabKeyEnum.SETTINGS_USER}>Settings</Link>,
					children: [
						{
							key: HomeTabKeyEnum.SETTINGS_USER,
							label: <Link to={HomeTabKeyEnum.SETTINGS_USER}>User</Link>,
						},
						{
							key: HomeTabKeyEnum.SETTINGS_LANGUAGE,
							label: <Link to={HomeTabKeyEnum.SETTINGS_LANGUAGE}>Language</Link>,
						},
					],
				},
				{
					key: "import",
					label: <Link to={HomeTabKeyEnum.VIDEOS_IMPORT}>Import</Link>,
					children: [
						{
							key: HomeTabKeyEnum.VIDEOS_IMPORT,
							label: <Link to={HomeTabKeyEnum.VIDEOS_IMPORT}>Video Import</Link>,
						},
						{
							key: HomeTabKeyEnum.ASSETS_IMPORT,
							label: <Link to={HomeTabKeyEnum.ASSETS_IMPORT}>Asset Import</Link>,
						},
					],
				},
				{
					key: "export",
					label: <Link to={HomeTabKeyEnum.ASSETS_EXPORT}>Asset Export</Link>,
				},
				// {
				// 	key: "scoring-episodes",
				// 	label: <Link to={HomeTabKeyEnum.EPISODE_SCORING}>Episode Scoring</Link>,
				// },
				{
					key: "mrss-feed",
					label: "Feed",
					children: [
						{
							key: "zype-mrss-feed",
							label: (
								<a
									href={`${process.env.REACT_APP_BACK_END_API}/zype/live-feed`}
									target="_blank"
									rel="noreferrer"
								>
									Zype
								</a>
							),
						},
					],
				},
			],
		},
		{
			key: "tag-and-score",
			label: (
				<MainLabelContainer>
					<span>Tag and Score</span>
					<CaretDownOutlined />
				</MainLabelContainer>
			),
			children: [
				{
					key: "scoring-episodes",
					label: <Link to={HomeTabKeyEnum.EPISODE_SCORING}>Episode Scoring</Link>,
				},
				{
					key: 'theme-tagger',
					label: (
						<a
							href={`https://theme-flare-detector.lovable.app/`}
							target="_blank"
							rel="noreferrer"
						>
							Theme Tagger
						</a>),
				}
			],
		},
		{
			key: HomeTabKeyEnum.ZYPE,
			label: <Link to={HomeTabKeyEnum.ZYPE}>Zype</Link>,
		},
		{
			key: HomeTabKeyEnum.WURL,
			label: <Link to={HomeTabKeyEnum.WURL}>Wurl</Link>,
		},
		{
			key: "scheduling-menu",
			label: (
				<MainLabelContainer>
					<span>Scheduling</span>
					<CaretDownOutlined />
				</MainLabelContainer>
			),
			children: [
				{
					key: HomeTabKeyEnum.CREATE_EPG,
					label: <Link to={HomeTabKeyEnum.CREATE_EPG}>Create EPG from Amagi Schedules</Link>,
				},
				{
					key: HomeTabKeyEnum.SCHEDULER,
					label: <Link to={HomeTabKeyEnum.SCHEDULER}>Scheduler</Link>,
				},
			],
		},
		{
			key: "auto-podder-menu",
			label: (
				<MainLabelContainer>
					<span>Auto Podder</span>
					<CaretDownOutlined />
				</MainLabelContainer>
			),
			children: [
				{
					key: HomeTabKeyEnum.AUTO_PODDER,
					label: <Link to={HomeTabKeyEnum.AUTO_PODDER}>AutoPodder</Link>,
				},
				{
					key: HomeTabKeyEnum.POST_LOG_PROCESSOR,
					label: (
						<Link to={HomeTabKeyEnum.POST_LOG_PROCESSOR}>Convert Post Log to Structure File </Link>
					),
				},
				{
					key: HomeTabKeyEnum.BATCH_JOBS,
					label: <Link to={HomeTabKeyEnum.BATCH_JOBS}>Batch Jobs</Link>,
				},
			],
		},
		{
			key: "register-menu",
			label: (
				<MainLabelContainer>
					<span>Register</span>
					<CaretDownOutlined />
				</MainLabelContainer>
			),
			children: [
				{
					key: HomeTabKeyEnum.VIEWERSHIP_UPLOADER,
					label: <Link to={HomeTabKeyEnum.VIEWERSHIP_UPLOADER}>Viewership Uploader</Link>,
				},
				{
					key: HomeTabKeyEnum.PAYMENT_MANAGER,
					label: <Link to={HomeTabKeyEnum.PAYMENT_MANAGER}>Payment Manager</Link>,
				},
			],
		},
		{
			key: "salesforce-menu",
			label: (
				<MainLabelContainer>
					<span>Salesforce</span>
					<CaretDownOutlined />
				</MainLabelContainer>
			),
			children: [
				{
					key: HomeTabKeyEnum.BUNDLE_SPOT_DATA,
					label: <Link to={HomeTabKeyEnum.BUNDLE_SPOT_DATA}>Bundle Spot Data</Link>,
				},
			]
		},
		{
			key: "google-signin-button",
			label: <div id="google-signin-div"></div>,
		},
		{
			key: "google-signin",
			label: (
				<div id="google-logout-div">
					<Button
						type="primary"
						icon={<PoweroffOutlined />}
						id="google-logout-button"
						onClick={() => {
							// Call the appropriate logout function from the Google Identity Toolkit
							window.google.accounts.id.disableAutoSelect();
							window.google.accounts.id.cancel();
							// Handle any additional logout steps here
							window.sessionStorage.removeItem("mvmgsid");
							// clear session storage
							window.sessionStorage.clear();
							window.location.reload();

							setCurrentUserEmail("");
						}}
					>
						Logout
					</Button>
				</div>
			),
		},
	];

	if (currentUserEmail.length === 0) {
		// logged out
		navigationMenu = [
			{
				key: "google-signin",
				label: <div id="google-signin-div"></div>,
			},
		];
	}

	let styledContent = (
		<StyledContent>
			<Outlet
				context={{
					languagesList,
					tagsList,
					setTagsList,
					seriesList,
					setseriesList,
					loading,
					setLoading,
					distributionPlatformData,
					setDistributionPlatformData,
					distributionPlatformSortObj,
					user,
					setUser,
					ratings,
					videoLists,
					setVideoLists,
				}}
			/>
		</StyledContent>
	);

	if (currentUserEmail.length === 0) {
		// logged out
		styledContent = (
			<NotLoggedInOrDataDiv>
				<h1>
					{isLoggingIn ? "Fetching your details. Please wait..." : "Please sign in to continue"}
				</h1>
			</NotLoggedInOrDataDiv>
		);
	}

	if (currentUserEmail.length && !user) {
		styledContent = <NotLoggedInOrDataDiv>Please wait...</NotLoggedInOrDataDiv>;
	}

	return (
		<>
			<Layout>
				<StyledHeader>
					<Image width={150} preview={false} src={imageUrls.logo} />
					<Menu
						theme="dark"
						mode="horizontal"
						items={navigationMenu}
						defaultSelectedKeys={["metadata-master", location.pathname]}
					/>
				</StyledHeader>
				{styledContent}
			</Layout>
		</>
	);
};

const router = createBrowserRouter([
	{
		path: HomeTabKeyEnum.BASE,
		element: <Root />,
		errorElement: <NotLoggedInOrDataDiv>Page Not found</NotLoggedInOrDataDiv>,
		children: [
			{
				path: HomeTabKeyEnum.EPISODE,
				element: <EpisodeComponent />,
			},
			{
				path: HomeTabKeyEnum.EPISODE + "/:ref_id",
				element: <EpisodeComponent />,
			},
			{
				path: HomeTabKeyEnum.VIDEOS,
				element: <VideosComponent />,
			},
			{
				path: HomeTabKeyEnum.VIDEOS + "/:videoId",
				element: <VideosComponent />,
			},
			{
				path: HomeTabKeyEnum.METADATA,
				element: <Metadata />,
			},
			{
				path: HomeTabKeyEnum.SERIES,
				element: <SeriesComponent />,
			},
			{
				path: HomeTabKeyEnum.TAGS,
				element: <TagsComponent />,
			},
			{
				path: HomeTabKeyEnum.ZYPE,
				element: <ZypeComponent />,
			},
			{
				path: HomeTabKeyEnum.WURL,
				element: <WurlComponent />,
			},
			{
				path: HomeTabKeyEnum.SETTINGS_LANGUAGE,
				element: <LanguageComponent />,
			},
			{
				path: HomeTabKeyEnum.SETTINGS_USER,
				element: <UserSettingsComponent />,
			},
			{
				path: HomeTabKeyEnum.ASSETS_EXPORT,
				element: <AssetsExportComponent />,
			},
			{
				path: HomeTabKeyEnum.VIDEOS_IMPORT,
				element: <FileUploaderPageContent />,
			},
			{
				path: HomeTabKeyEnum.ASSETS_IMPORT,
				element: <AssetsImport />,
			},
			{
				path: HomeTabKeyEnum.AUTO_PODDER,
				element: <AutoPodderComponent />,
			},
			{
				path: HomeTabKeyEnum.POST_LOG_PROCESSOR,
				element: <PostLogProcessorComponent />,
			},
			{
				path: HomeTabKeyEnum.BATCH_JOBS,
				element: <BatchJobsComponent />,
			},
			{
				path: HomeTabKeyEnum.CREATE_EPG,
				element: <EPGInputsUploadComponent />,
			},
			{
				path: HomeTabKeyEnum.SCHEDULER,
				element: <SchedulerComponent />,
			},
			{
				path: HomeTabKeyEnum.DISTRIBUTIONR_PLATFORM,
				element: <DistributionPlatformComponent />,
			},
			{
				path: HomeTabKeyEnum.CAPTIONS,
				element: <Captions />,
			},
			{
				path: HomeTabKeyEnum.CUEPOINTS,
				element: <CuePointsComponent />,
			},
			{
				path: HomeTabKeyEnum.THUMBNAILS,
				element: <ThumbnailsComponent />,
			},
			{
				path: HomeTabKeyEnum.PROMOS,
				element: <PromosComponent />,
			},
			{
				path: HomeTabKeyEnum.ASSET_MATCHING,
				element: <TitleConflictsComponent />,
			},
			{
				path: HomeTabKeyEnum.VIEWERSHIP_UPLOADER,
				element: <ViewershipUploadComponent />,
			},
			{
				path: HomeTabKeyEnum.PAYMENT_MANAGER,
				element: <PaymentManager />,
			},
			// {
			// 	path: HomeTabKeyEnum.ZYPE_EXPORT,
			// 	element: <ZypeExport />,
			// },
			{
				path: HomeTabKeyEnum.WURL_EXPORT,
				element: <WurlExport />,
			},
			{
				path: HomeTabKeyEnum.VIDEO_LISTS,
				element: <VideoListsComponent />,
			},
			{
				path: HomeTabKeyEnum.EPISODE_SCORING,
				element: <FileUploaderPageContent />,
			},
			{
				path: HomeTabKeyEnum.BUNDLE_SPOT_DATA,
				element: <BundleSpotDataUpload />,
			},
		],
	},
]);
const App = () => {
	return (
		<>
			<RouterProvider router={router} />
		</>
	);
};

export default App;
