import React from "react";
import usePromosHook from "./hooks/usePromosHook";
import { CustomTable } from "../series/StyledComponent";
import useScreenSizeHook from "../utils/useScreenSizeHook";
import { Button, Col, Row } from "antd";
import { ComponentTitle, PageTitleLastCol, SearchForm } from "../custom-components";
import AddEditPromoModal from "./modals/AddEditPromoModal";

const PromosComponent = () => {
	const { height } = useScreenSizeHook();
	const {
		form,
		promoColumns,
		pagination,
		isLoading,
		editHandler,
		createUpdateCuePoint,
		setIsPromoFormOpen,
		handleTableChange,
		promoData,
		promoEditId,
		onCancelPromoForm,
		isPromoFormOpen,
	} = usePromosHook();
	return (
		<>
			<Row justify="space-between" align="middle">
				<Col>
					<ComponentTitle>Promos</ComponentTitle>
				</Col>
				<PageTitleLastCol>
					<Button onClick={() => setIsPromoFormOpen(true)} type="primary">
						+ Create Promo
					</Button>
					{/* <SearchForm form={form} searchData={searchData} placeholder="Search by Ref ID" /> */}
				</PageTitleLastCol>
			</Row>
			<CustomTable
				onChange={handleTableChange}
				columns={promoColumns}
				dataSource={promoData}
				scroll={{ y: height - 265 }}
				loading={isLoading}
				pagination={pagination}
				onRow={(record: any) => {
					return {
						onClick: () => {
							editHandler(record);
						},
					};
				}}
			/>
			{isPromoFormOpen && (
				<AddEditPromoModal
					form={form}
					promoEditId={promoEditId}
					onCancelPromo={onCancelPromoForm}
					onOkPromo={createUpdateCuePoint}
					loading={isLoading}
				/>
			)}
		</>
	);
};

export default PromosComponent;
