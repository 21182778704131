import { message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { PaymentData } from '../types/main';
import { getUploadSignedURL } from '../../../utils/index';
import { authAxios } from "../../../utils/session_utils";
import { StatusResponse, UploadStatus, NullableStatusResponse } from "./statusUtils";

export const handleInputChange = (
  value: any,
  key: string,
  column: string,
  dataSource: PaymentData[],
  setDataSource: React.Dispatch<React.SetStateAction<PaymentData[]>>
) => {
  const newData = dataSource.map(item => (item.key === key ? { ...item, [column]: value } : item));
  setDataSource(newData);
};

export const handleAddLine = (
  dataSource: PaymentData[],
  setDataSource: React.Dispatch<React.SetStateAction<PaymentData[]>>
) => {
  const newLine: PaymentData = {
    key: `${dataSource.length + 1}`,
    domain: '',
    platform: '',
    paymentDate: '',
    partner: '',
    channel: '',
    territory: '',
    amount: '',
    department: '',
    title: '',
    type: '',
    description: '',
    label: '',
    registerName: '',
    year: '',
    quarter: '',
    month: '',
    impressions: '',
    cpms: '',
    selected: false,
    allocationStrategy: {
      platform: true,
      partner: true,
      channel: true,
      territory: true,
      allocationStrategy: {} 
    },
    allocationLevel: '',
    allocationPeriod: '',
    linkedViewershipPool: '',
    linkedToViewership: false,
    toBeProcessed: true,
  };
  setDataSource([...dataSource, newLine]);
};

export const handleRemoveLine = (
  selectedRowKeys: React.Key[],
  dataSource: PaymentData[],
  setDataSource: React.Dispatch<React.SetStateAction<PaymentData[]>>,
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
) => {
  const newData = dataSource.filter(item => !selectedRowKeys.includes(item.key));
  setDataSource(newData);
  setSelectedRowKeys([]);
};

export const handleSelectAll = (
  dataSource: PaymentData[],
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
) => {
  const allKeys = dataSource.map(item => item.key);
  setSelectedRowKeys(allKeys);
};


export const handleFileSelect = (
  fileName: string,
  fileId: string,
  key: string,
  dataSource: PaymentData[],
  setDataSource: React.Dispatch<React.SetStateAction<PaymentData[]>>
) => {
  setDataSource(prevDataSource =>
    prevDataSource.map(item =>
      item.key === key
        ? { ...item, fileName: fileName, fileId: fileId }
        : item
    )
  );
};

const generateJobId = () => {
  const timestamp = Date.now();
  const random = Math.floor(Math.random() * 1000);
  return `job_${timestamp}_${random}`;
};
  

export const getFilename = (uploadedFilename = '', jobId: string = generateJobId()) => {
  if (uploadedFilename) {
    const baseName = uploadedFilename.replace(/\.[^/.]+$/, '');
    return `${baseName}_${jobId}_payment_uploader.csv`;
  }
  return `${jobId}_payment_uploader.csv`;
};


export const handleUploadData = async (
  dataSource: PaymentData[],
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
  setStatus: React.Dispatch<React.SetStateAction<NullableStatusResponse>>,
  setOperationId: React.Dispatch<React.SetStateAction<string | null>>,
  setShowUploadView: React.Dispatch<React.SetStateAction<boolean>>,
  setViewershipPool: React.Dispatch<React.SetStateAction<any>>,
  setIsProgressModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  onUploadSuccess: () => void
) => {

  // Reset states
  setIsUploading(true);
  setStatus({ status: 'initiating' });
  setIsProgressModalVisible(true);
  setErrorMessage('');
  
  try {
    // Validate data first
    const validationErrors = validatePaymentData(dataSource);
    if (validationErrors.length > 0) {
      throw new Error(validationErrors.join('\n'));
    }

    const { operationId } = await uploadPaymentData(dataSource);
    
    if (!operationId) {
      throw new Error('No operation ID received from server');
    }

    setOperationId(operationId);
    
    await pollUploadStatus(
      operationId, 
      setStatus, 
      setIsUploading, 
      setShowUploadView, 
      setViewershipPool, 
      setIsProgressModalVisible, 
      setErrorMessage, 
      setIsErrorModalVisible,
      onUploadSuccess
    );
  } catch (error) {
    console.error('Error in upload process:', error);
    setIsUploading(false);
    setStatus({ 
      status: 'error', 
      message: error instanceof Error ? error.message : 'An unknown error occurred'
    });
    setIsProgressModalVisible(false);

  
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message; 
    } else if (typeof error === 'string') {
      errorMessage = error;
    }
    

    setErrorMessage(errorMessage);
    console.log('Error message set:', errorMessage);
    setIsErrorModalVisible(true);
    
    throw error;
  }
};



export const uploadPaymentData = async (dataSource: PaymentData[]) => {
  try {
    // Validate data before sending
    const validationErrors = validatePaymentData(dataSource);
    if (validationErrors.length > 0) {
      throw new Error(validationErrors.join('\n'));
    }

    // Clean the data before sending
    const cleanedData = dataSource.map(row => ({
      ...row,
      amount: row.amount.trim(),
      partner: row.partner.trim(),
      channel: row.channel.trim(),
      territory: row.territory.trim(),
      allocationStrategy: {
        platform: true,
        partner: true,
        channel: true,
        territory: true,
        allocationStrategy: {}
      }
    }));



    const response = await authAxios({
      method: 'POST',
      url: `${process.env.REACT_APP_BACK_END_API}/snowflake/invoice_upload`,
      data: cleanedData,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.data || !response.data.operationId) {
      throw new Error('Invalid response from server');
    }

    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      throw new Error(error.response.data.message || 'Duplicate payments found');
    } else {
      throw new Error(error.response.data.message || 'Unable to allocate succesfully');
    }
  }
};



interface OperationStatusResponse {
  status: UploadStatus;
  message?: string;
  data?: any;
}

export const pollOperationStatus = async (operationId: string): Promise<OperationStatusResponse> => {
  try {
    const response = await authAxios({
      method: 'GET',
      url: `${process.env.REACT_APP_BACK_END_API}/snowflake/payment-upload-job-status/${operationId}`,
    });
    
    // Ensure the response matches our expected format
    const data = response.data;
    return {
      status: data.status as UploadStatus,
      message: data.message,
      data: data.data
    };
  } catch (error: any) {
    // Detailed error handling
    console.error('Detailed polling error:', error);

    // If the error has a response from the server
    if (error.response) {
      console.log('Server responded with error:', error.response.data);
      return {
        status: 'error',
        message: error.response.data.message || error.response.data.error || 'An error occurred',
        data: error.response.data
      };
    } 
    
    // Network error or other axios error
    if (error instanceof Error) {
      return { 
        status: 'error', 
        message: error.message 
      };
    } 
    
    // Fallback
    return { 
      status: 'error', 
      message: 'An unknown error occurred' 
    };
  }
};


export const pollUploadStatus = async (
  operationId: string,
  setStatus: React.Dispatch<React.SetStateAction<NullableStatusResponse>>,
  setIsUploading: (isUploading: boolean) => void,
  setShowUploadView: (show: boolean) => void,
  setViewershipPool: React.Dispatch<React.SetStateAction<any>>,
  setIsProgressModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  onUploadSuccess: () => void
) => {
  const pollInterval = 2000; 
  const maxAttempts = 150;
  let attempts = 0;
  let shouldContinuePolling = true;

  // Reset error states before starting polling
  setErrorMessage('');
  setIsErrorModalVisible(false);

  const poll = async () => {
    if (!shouldContinuePolling) return;

    try {
      const data = await pollOperationStatus(operationId);


      // Add more comprehensive status mapping
      const frontendStatus: StatusResponse = mapToFrontendStatus(data);

      // Reset any previous error state if status is not error
      if (data.status !== 'error') {
        setErrorMessage('');
        setIsErrorModalVisible(false);
      }

      // Use the original status for switch cases
      switch (data.status as UploadStatus) {
        case 'processing':
        case 'uploaded':
        case 'normalized':
        case 'checking_for_dupes':
          attempts++;
          setStatus(frontendStatus);
          if (attempts < maxAttempts) {
            setTimeout(poll, pollInterval);
          } else {
            handleErrorState('Operation timed out');
          }
          break;
        case 'viewership_fetched':
          shouldContinuePolling = false;
          setStatus({ 
            status: 'viewership_fetched',
            message: 'Upload completed! Switching to allocation mode...'
          });
          setIsUploading(false);
          setShowUploadView(false);
          setViewershipPool(data.data);
          setIsProgressModalVisible(false);
          onUploadSuccess();  
          break;
        case 'error':
          shouldContinuePolling = false; // Stop polling
          handleErrorState(data.message || 'An error occurred during the operation');
          break;
        default:
          console.warn(`Unexpected status: ${data.status}, continuing to poll`);
          attempts++;
          if (attempts < maxAttempts) {
            setTimeout(poll, pollInterval);
          } else {
            shouldContinuePolling = false; // Stop polling
            handleErrorState(`Polling stopped after ${maxAttempts} attempts. Last status: ${data.status}`);
          }
      }
    } catch (error) {
      console.error('Comprehensive Error during polling:', error);
      attempts++;
      if (attempts < maxAttempts) {
        console.log(`Polling attempt ${attempts} failed. Retrying...`);
        setTimeout(poll, pollInterval);
      } else {
        shouldContinuePolling = false; // Stop polling
        handleErrorState('Polling failed after maximum attempts');
      }
    }
  };


  const mapToFrontendStatus = (data: any): StatusResponse => {
    // More explicit status mapping
    const statusMap: Record<string, StatusResponse> = {
      'checking_for_dupes': {
        status: 'processing',
        message: 'Checking for duplicates...'
      },
      'payment_dupes_checked': {
        status: 'processing',
        message: 'Duplicate check completed'
      },
      'processing': {
        status: 'processing',
        message: 'Processing upload...'
      }
    };

    return statusMap[data.status] || {
      status: data.status,
      message: data.message,
      data: data.data
    };
  };

  const handleErrorState = (errorMsg: string) => {
    console.error('Error State Triggered:', errorMsg);
    setStatus({ 
      status: 'error',
      message: errorMsg
    });
    setIsUploading(false);
    setIsProgressModalVisible(false);
    setErrorMessage(errorMsg);
    setIsErrorModalVisible(true);
  };

  poll();
};



const validatePaymentData = (data: PaymentData[]): string[] => {
  const errors: string[] = [];
  
  data.forEach((row, index) => {
    // Check for required fields
    if (!row.partner || row.partner.trim() === '') {
      errors.push(`Row ${index + 1}: Partner is required`);
    }
    if (!row.channel || row.channel.trim() === '') {
      errors.push(`Row ${index + 1}: Channel is required`);
    }
    if (!row.territory || row.territory.trim() === '') {
      errors.push(`Row ${index + 1}: Territory is required`);
    }
    if (!row.amount || row.amount.trim() === '') {
      errors.push(`Row ${index + 1}: Amount is required`);
    }
    
    // Validate amount format
    if (row.amount && isNaN(parseFloat(row.amount))) {
      errors.push(`Row ${index + 1}: Amount must be a valid number`);
    }

    // Ensure required date fields are present
    if (!row.year || row.year.trim() === '') {
      errors.push(`Row ${index + 1}: Year is required`);
    }
    if (!row.quarter || row.quarter.trim() === '') {
      errors.push(`Row ${index + 1}: Quarter is required`);
    }
    if (!row.month || row.month.trim() === '') {
      errors.push(`Row ${index + 1}: Month is required`);
    }

    // Validate allocationStrategy
    if (!row.allocationStrategy || typeof row.allocationStrategy !== 'object') {
      errors.push(`Row ${index + 1}: Invalid allocation strategy`);
    }
  });

  return errors;
};



export const handleAllocate = async (
  keys: string[], 
  dataSource: PaymentData[], 
  setAllocationStatus: React.Dispatch<React.SetStateAction<string>>,
  setIsProgressModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setAllocationProgress: React.Dispatch<React.SetStateAction<NullableStatusResponse>>
): Promise<boolean> => {
  setAllocationProgress({ status: 'allocation_started' });
  setAllocationStatus('processing');

  const userEmail = window.sessionStorage.getItem("mvmgsid") || ""; 

  const selectedRecords = keys.map(key => {
    const record = dataSource.find(r => r.key === key);
    if (!record) {
      console.error(`No record found for key: ${key}`);
      return null;
    }
    return {
      key: record.key,
      platform: record.platform,
      partner: record.partner,
      channel: record.channel,
      territory: record.territory,
      amount: record.amount,
      paymentDate: record.paymentDate,
      allocationStrategy: record.allocationStrategy,
      allocationLevel: record.allocationLevel,
      allocationPeriod: record.allocationPeriod,
      filename: record.filename
    };
  }).filter(record => record !== null);



  if (selectedRecords.length === 0) {
    console.error('No valid records selected for allocation');
    return false;
  }

  const payload = {
    selectedRecords,
    userEmail
  };

  setIsProgressModalVisible(true);

  try {
    const response = await authAxios({
      method: 'POST',
      url: `${process.env.REACT_APP_BACK_END_API}/snowflake/allocate`,
      data: payload,
    });

    const { allocationOperationId } = response.data;

    pollAllocationStatus(
      allocationOperationId,
      setAllocationStatus,
      keys,
      setIsProgressModalVisible,
      setErrorMessage,
      setIsErrorModalVisible,
      setAllocationProgress  
    );

    return true;
  } catch (error) {
    console.error('Error during allocation:', error);
    setIsProgressModalVisible(false);
    setErrorMessage('Failed to initiate allocation process');
    setIsErrorModalVisible(true);
    return false;
  }
};


const pollAllocationStatus = async (
  operationId: string,
  setAllocationStatus: React.Dispatch<React.SetStateAction<string>>,
  keys: string[],
  setIsProgressModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setIsErrorModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setAllocationProgress: React.Dispatch<React.SetStateAction<NullableStatusResponse>> 
) => {
  let attempts = 0;
  const maxAttempts = 60;
  const pollInterval = 5000;
  let shouldContinuePolling = true;

  const stopPolling = () => {
    shouldContinuePolling = false;
  };

  const poll = async () => {
    if (!shouldContinuePolling) return;

    try {
      const data = await pollAllocationStatusAPI(operationId);

      // Always update allocation progress
      setAllocationProgress({ 
        status: data.status, 
        message: data.message 
      });

      // Stop polling if any terminal state is reached
      if (['allocation_completed', 'invoking_lambda', 'error'].includes(data.status)) {
        stopPolling();
      }

      setAllocationStatus(data.status);

      switch (data.status) {
        case 'allocation_started':
        case 'pre_processing':
        case 'checking_rollup_table_for_dupes':
        case 'performing_rollups':
        case 'preparing_lambda_payloads':
        case 'invoking_lambda':
          attempts++;
          if (attempts < maxAttempts && shouldContinuePolling) {
            setTimeout(poll, pollInterval);
          } else {
            stopPolling();
            // Only call handleErrorState if max attempts are reached
            if (attempts >= maxAttempts) {
              handleErrorState('Allocation operation timed out');
            }
          }
          break;
        case 'allocation_completed':
          stopPolling();
          setAllocationProgress({ status: 'completed' }); 
          setIsProgressModalVisible(false);
          break;
        case 'error':
          stopPolling();
          handleErrorState(data.message || 'An error occurred during the allocation operation');
          break;
        default:
          attempts++;
          if (attempts < maxAttempts && shouldContinuePolling) {
            setTimeout(poll, pollInterval);
          } else {
            stopPolling();
            // Only call handleErrorState if max attempts are reached
            if (attempts >= maxAttempts) {
              handleErrorState(`Polling stopped after ${maxAttempts} attempts. Last status: ${data.status}`);
            }
          }
      }
    } catch (error) {
      attempts++;
      if (attempts < maxAttempts && shouldContinuePolling) {
        setTimeout(poll, pollInterval);
      } else {
        stopPolling();
        // Only call handleErrorState if max attempts are reached
        if (attempts >= maxAttempts) {
          handleErrorState('Allocation polling failed after maximum attempts');
        }
      }
    }
  };

  const handleErrorState = (errorMsg: string) => {
    setAllocationStatus('error');
    setAllocationProgress({ status: 'error', message: errorMsg }); 
    setIsProgressModalVisible(false);
    setErrorMessage(errorMsg);
    setIsErrorModalVisible(true);
  };

  poll();

  return stopPolling;
};
  
  const pollAllocationStatusAPI = async (operationId: string) => {
    try {
      const response = await authAxios({
        method: 'GET',
        url: `${process.env.REACT_APP_BACK_END_API}/snowflake/payment-upload-job-status/${operationId}`,
      });
      
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      if (error instanceof Error) {
        return { status: 'error', message: error.message };
      } else {
        return { status: 'error', message: 'An unknown error occurred' };
      }
    }
  };







//s3 file handlers
export const handleSaveFile = async (
  currentFile: UploadFile | null,
  uploadUrls: { [key: string]: { url: string; fileId: string } },
  renamedFile: string,
  fileList: UploadFile[],
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>,
  renamedFiles: { [key: string]: string },
  setRenamedFiles: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>,
  dataSource: PaymentData[],
  setDataSource: React.Dispatch<React.SetStateAction<PaymentData[]>>,
  uploadedFiles: { [key: string]: boolean },
  setUploadedFiles: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>,
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const file = currentFile;
  if (!file) return;

  const { url, fileId } = uploadUrls[file.uid];
  const finalFileName = `${fileId}-${renamedFile}`;

  try {
    // Check if file was already uploaded
    if (!uploadedFiles[file.uid]) {
      await handleS3PaymentUpload(file, url, finalFileName); // Ensure the renamed file is used for uploading
      message.success('File uploaded successfully');
      setUploadedFiles(prev => ({ ...prev, [file.uid]: true })); // Mark file as uploaded
    }

    const newFileList = fileList.map(f =>
      f.uid === file.uid ? { ...f, name: renamedFile } : f // Update name to just renamedFile for display
    );
    setFileList(newFileList);

    const newRenamedFiles = { ...renamedFiles, [file.uid]: renamedFile };
    setRenamedFiles(newRenamedFiles);

    const newDataSource = dataSource.map(item =>
      item.key === file.uid ? { ...item, fileName: renamedFile, fileId } : item 
    );
    setDataSource(newDataSource);

  } catch (error) {
    message.error('Error uploading file');
  }

  setIsModalVisible(false);
};

export const handleS3PaymentUpload = async (
  file: UploadFile,
  url: string,
  fileName: string
) => {
  const response = await fetch(url, {
    method: 'PUT',
    body: file.originFileObj,
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to upload file');
  }

};

export const handleFileChange = async (
  { file, fileList }: { file: UploadFile; fileList: UploadFile[] },
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>,
  setCurrentFile: React.Dispatch<React.SetStateAction<UploadFile | null>>,
  setRenamedFile: React.Dispatch<React.SetStateAction<string>>,
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setUploadUrls: React.Dispatch<React.SetStateAction<{ [key: string]: { url: string; fileId: string } }>>,
  setFileId: React.Dispatch<React.SetStateAction<string | null>>
) => {
  setFileList(fileList);
  setCurrentFile(file);
  setRenamedFile(file.name);
  setIsModalVisible(true);

  try {
    const response = await getUploadSignedURL('application/octet-stream', file.name); // Fetch presigned URL with original file name
    const { url, fileId } = response;
    setUploadUrls(prev => ({ ...prev, [file.uid]: { url, fileId } }));
    setFileId(fileId);
  } catch (error) {
    console.error('Error fetching pre-signed URL:', error);
  }
};


