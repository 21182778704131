import { useState, useEffect } from 'react';
import { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { authAxios, getEmailFromSessionStorage } from "../../utils/session_utils";
import { notification } from 'antd';


export interface MetadataRecord {
  id: string;
  ref_id: string;
  title: string;
  title_search_index: string;
  episode_status: string;
  series_status: string;
  series_code: string;
  titles: string;
  episode_id: string;
  series_id: string;
  content_provider: string;
  language: string;
}

interface UseMetadataResult {
    allMetadataData: MetadataRecord[];
    loading: boolean;
    pagination: TablePaginationConfig;
    handleTableChange: (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<MetadataRecord> | SorterResult<MetadataRecord>[]
    ) => void;
    setAllMetadataData: React.Dispatch<React.SetStateAction<MetadataRecord[]>>;
  }

  
export const useMetadata = (): UseMetadataResult => {
    const [allMetadataData, setAllMetadataData] = useState<MetadataRecord[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
      current: 1,
      pageSize: 10,
      total: 0,
    });
  

  const fetchMetadata = async () => {
    setLoading(true);
    try {
      const email = getEmailFromSessionStorage();
      const config = {
        method: "GET",
        url: `${process.env.REACT_APP_BACK_END_API}/snowflake/metadata/related_tables`,
        params: { email },
      };
      const response = await authAxios(config);
      const { total, data } = response.data;
      
      if (Array.isArray(data)) {
        const transformedData = data.map(item => ({
            id: item.METADATA_ID,
            ref_id: item.REF_ID,
            title: item.TITLE,
            title_search_index: item.TITLE_SEARCH_INDEX,
            episode_status: item.EPISODE_STATUS,
            episode_id: item.EPISODE_ID,
            series_id: item.SERIES_ID,
            series_status: item.SERIES_STATUS,
            series_code: item.SERIES_CODE,
            titles: item.TITLES,
            content_provider: item.CONTENT_PROVIDER,
            language: item.LANGUAGE
          }));
          setAllMetadataData(transformedData);        
          setPagination(prev => ({ ...prev, total }));
      } else {
        console.error('Data is not an array:', data);
        setAllMetadataData([]);
      }
    } catch (error) {
      console.error('Error fetching metadata:', error);
      notification.error({
        message: "Failed to Fetch Metadata",
        description: "An error occurred while fetching metadata. Please try again later.",
      });
      setAllMetadataData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMetadata();
  }, []);

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<MetadataRecord> | SorterResult<MetadataRecord>[]
  ) => {
    setPagination(newPagination);
  };

  return { allMetadataData, loading, pagination, handleTableChange, setAllMetadataData };
};

