import { PaginationProps, FormInstance, message, notification } from "antd";
import { Dispatch, SetStateAction } from "react";
import { SortObj } from "../../videos/videos";
import { authAxios} from "../../utils/session_utils";
import { makeKeyInLowerCaseHelper } from "../../utils";
import { UnmatchedAssetInterface } from "../interfaces";
import { AlternateTitleSubmissionData } from "../interfaces";

const titleConflictRoute = "title-conflict";
const alternateTitleRoute = "alternate-title";



export const getTitleConflictsData = async (
    setTitleConflictsData: Dispatch<SetStateAction<UnmatchedAssetInterface[]>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    pagination: PaginationProps,
    titleSortObj: SortObj,
    searchQuery: string,
    titleFilterObj?: any
) => {
    try {
        const config: any = {
            method: "GET",
            url: `${
                process.env.REACT_APP_BACK_END_API
            }/snowflake/${titleConflictRoute}/?pagination=${JSON.stringify(
                pagination
            )}&sortObj=${JSON.stringify(titleSortObj ?? {})}&filter=${JSON.stringify(
                titleFilterObj ?? {}
            )}&searchQuery=${searchQuery}`,
            headers: {
                "Content-Type": "application/json",
            },
        };
        const response = await authAxios(config);
        // Check if rows exists and is an array
        if (response.data.rows && Array.isArray(response.data.rows)) {
            const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
            setTitleConflictsData(convertedObject as unknown as UnmatchedAssetInterface[]);
        } else {
            // Handle empty data
            console.log('No title conflicts data found or invalid format');
            setTitleConflictsData([]);
        }
        setLoading(false);
    } catch (error) {
        console.error(error);
        // Set empty array on error
        setTitleConflictsData([]);
        setLoading(false);
        // Don't throw error to prevent UI breaking
        console.error('Error fetching title conflicts data:', error);
    }
};

export const saveUpdateTitleConflict = async (values: UnmatchedAssetInterface, editId?: string) => {
    try {
        const bodyData = {
            ...values,
            id: editId,
        };
        const config = {
            method: editId ? "put" : "post",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/${titleConflictRoute}`,
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify(bodyData),
        };
        const response = await authAxios(config);
        if (response.status === 200) {
            notification.info({
                duration: 5,
                message: "Invalid Ref ID",
                description: "This ref ID does not exist in our database.",
            });
            throw new Error(response.data.message);
        }
        return response;
    } catch (error: any) {
        if (error?.response.status === 409) {
            notification.error({
                duration: 5,
                message: "Record already exists",
                description: "This title conflict already exists.",
            })
        }
        throw new Error("error in adding or updating title conflict", { cause: error });
    }
};


export const getAvailableMetadata = async () => {
    try {
        const config = {
            method: "GET",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/metadata/available`,
            headers: {},
        };
        const response = await authAxios(config);
        return response.data;
    } catch (error) {
        console.error("error in fetching available metadata", error);
        throw new Error("error in fetching available metadata", { cause: error });
    }
};

export const assignMetadataToTitle = async (titleId: string, metadataId: string) => {
    try {
        const config = {
            method: "POST",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/${titleConflictRoute}/assign-metadata`,
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify({ titleId, metadataId }),
        };
        const response = await authAxios(config);
        return response.data;
    } catch (error) {
        console.error("error in assigning metadata to title", error);
        throw new Error("error in assigning metadata to title", { cause: error });
    }
};


export const saveAllTitleConflicts = async (titleConflicts: UnmatchedAssetInterface[], userEmail: string) => {
    try {
        console.log('saveAllTitleConflicts called with titleConflicts:', titleConflicts);
        console.log('userEmail:', userEmail);
        
        const requestPayload = { titleConflicts, userEmail };
        console.log('Request payload in API function:', requestPayload);
        
        const config = {
            method: "post",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/title-conflict/batch`,
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify(requestPayload),
        };
        
        console.log('Request config:', {
            method: config.method,
            url: config.url,
            headers: config.headers
        });

        const response = await authAxios(config);
        console.log('API response:', response);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Unexpected response status");
        }
    } catch (error: any) {
        console.error("Error in saving all title conflicts", error);
        console.error("Error details:", error.response ? error.response.data : 'No response data');
        notification.error({
            message: "Save Failed",
            description: "An error occurred while sending title conflicts to the server. Please try again.",
        });
        throw new Error("Error in saving all title conflicts", { cause: error });
    }
};




export const createSaveUpdateHandler = (
    form: FormInstance,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setIsAlternateTitleFormOpen: React.Dispatch<React.SetStateAction<boolean>>,
    editId?: string,
    onSuccess?: () => void
  ) => {
    return async () => {
      try {
        // Validate form fields
        const values = await form.validateFields();
        setIsLoading(true);
  
        // Transform form data to match API expectations
        const submissionData: AlternateTitleSubmissionData = {
          alternate_title_ref_id: values.alternate_title_ref_id,
          alternate_title: values.alternate_title,
          notes: values.notes
        };
  
        // Call the API function
        await saveUpdateAlternateTitle(submissionData, editId);
        
        // Show success message
        message.success(`Alternate title successfully ${editId ? 'updated' : 'created'}`);
        
        // Close modal and reset form
        setIsAlternateTitleFormOpen(false);
        form.resetFields();
        
        // Call success callback if provided (e.g., to refresh data)
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        console.error("Form submission error:", error);
      } finally {
        setIsLoading(false);
      }
    };
  };
  
  /**
   * Save or update an alternate title
   * 
   * @param values The data to save
   * @param editId Optional ID for editing an existing record
   * @returns API response
   */
  export const saveUpdateAlternateTitle = async (values: AlternateTitleSubmissionData, editId?: string, userEmail?: string) => {
    try {
      const bodyData = {
        ...values,
        userEmail
      };
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_BACK_END_API}/snowflake/${alternateTitleRoute}`,
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(bodyData),
      };
      const response = await authAxios(config);
      if (response.status === 200) {
        notification.info({
          duration: 5,
          message: "Invalid Ref ID",
          description: "This ref ID does not exist in our database.",
        });
        throw new Error(response.data.message);
      }
      return response;
    } catch (error: any) {
      if (error?.response?.status === 409) {
        notification.error({
          duration: 5,
          message: "Record already exists",
          description: "This alternate title already exists.",
        });
      }
      throw new Error("Error in adding or updating alternate title", { cause: error });
    }
  };
  


  