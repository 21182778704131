import { DatePicker, Form, Modal } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { Dispatch, SetStateAction } from "react";

type SET_DAYJS_ACTION_TYPE = Dispatch<SetStateAction<Dayjs | Dayjs[] | undefined>>;
type SET_STRING_ACTION_TYPE = Dispatch<SetStateAction<string>>;
type DAY_JS_UNDEFINED = Dayjs | Dayjs[] | undefined;

type ActiveDatesModalProps = {
	isOpen: boolean;
	closeActiveDatesModal: () => void;
	saveActiveDateHandler: () => void;
	platformName: string;
	setStartDate: SET_STRING_ACTION_TYPE;
	setEndDate: SET_STRING_ACTION_TYPE;
	startDayJS: DAY_JS_UNDEFINED;
	setStartDayJS: SET_DAYJS_ACTION_TYPE;
	endDayJS: DAY_JS_UNDEFINED;
	setEndDayJS: SET_DAYJS_ACTION_TYPE;
};

const ActiveDates: React.FC<ActiveDatesModalProps> = ({
	isOpen,
	closeActiveDatesModal,
	platformName,
	saveActiveDateHandler,
	setStartDate,
	setEndDate,
	startDayJS,
	setStartDayJS,
	endDayJS,
	setEndDayJS,
}) => {
	const getSingleDayjs = (value: Dayjs | Dayjs[] | undefined): Dayjs | undefined => {
		if (dayjs.isDayjs(value)) return value;
		if (Array.isArray(value) && value.length > 0 && dayjs.isDayjs(value[0])) return value[0];
		return undefined;
	};

	return (
		<Modal
			wrapProps={{ style: { zIndex: "1050" } }}
			open={isOpen}
			title={`Active Dates for ${platformName}`}
			onCancel={closeActiveDatesModal}
			closeIcon={null}
			okText="Save"
			cancelText="Cancel"
			onOk={saveActiveDateHandler}
			maskClosable={false}
		>
			<Form.Item label="Activate">
				<DatePicker
					onChange={(date: Dayjs | null, dateString: string) => {
						setStartDate(dateString);
						setStartDayJS(date ? date : undefined);
					}}
					format={["MM/DD/YYYY"]}
					placeholder="MM/DD/YYYY"
					value={getSingleDayjs(startDayJS)}
				/>
			</Form.Item>
			<Form.Item label="Deactivate">
				<DatePicker
					onChange={(date: Dayjs | null, dateString: string) => {
						setEndDate(dateString);
						setEndDayJS(date ? date : undefined);
					}}
					format={["MM/DD/YYYY"]}
					placeholder="MM/DD/YYYY"
					value={getSingleDayjs(endDayJS)}
				/>
			</Form.Item>
		</Modal>
	);
};

export default ActiveDates;
