import React, { useState } from "react";
import { Row, Col, notification } from "antd";
import useTitleConflicts from "./hooks/useTitleConflicts";
import useScreenSizeHook from "../utils/useScreenSizeHook";
import UnmatchedAssetsTable from "./components/UnmatchedAssets";
import MetadataPool from "./components/MetadataPool";
import { UnmatchedAssetInterface } from './interfaces';
import { MetadataRecord, useMetadata } from './hooks/useMetadata';
import { saveAllTitleConflicts } from './hooks/utils';
import RemoveAssetModal from './modal/RemoveAssetModal'

const AssetMatchingPage: React.FC = () => {
    const { height } = useScreenSizeHook();
    const titleConflictsHook = useTitleConflicts();
    const { allMetadataData, setAllMetadataData, loading: metadataLoading } = useMetadata();
    const [selectedConflict, setSelectedConflict] = useState<UnmatchedAssetInterface | null>(null);
    const [linkedMetadata, setLinkedMetadata] = useState<{ [key: string]: { ref_id: string, operation: string, language: string, title: string } }>({});
    // Track processing records
    const [processingRecords, setProcessingRecords] = useState<{ [key: string]: boolean }>({});
	const [currentUserEmail ] = useState<string>(
		loadSessionStateByKey("mvmgsid", "")
	);
    const handleConflictSelect = (record: UnmatchedAssetInterface) => {
        if (record) {
            setSelectedConflict(record);
        }
    };
    function loadSessionStateByKey(key: string, defaultValue: any) {
        const item = window.sessionStorage.getItem(key);
        return item != null ? item : defaultValue;
    }
    
    // Mark a record as processing and remove it after 5 seconds
    const markAsProcessing = (recordId: string) => {
        setProcessingRecords(prev => ({
            ...prev,
            [recordId]: true
        }));
        
        // Set a timer to remove the record after 5 seconds
        setTimeout(() => {
            // Remove from processing records state
            setProcessingRecords(prev => {
                const updated = { ...prev };
                delete updated[recordId];
                return updated;
            });
            
            // Remove from the title conflicts data
            titleConflictsHook.removeRecordFromDisplay(recordId);
        }, 5000);
    };

    const handleMetadataLink = (metadata: MetadataRecord, operation: string) => {
        if (selectedConflict && selectedConflict.ref_id) {
            try {
                setLinkedMetadata(prev => ({
                    ...prev,
                    [selectedConflict.ref_id]: {
                        ref_id: metadata.ref_id || '',
                        operation,
                        language: metadata.language || '',
                        title: metadata.title || ''
                    }
                }));
                
                // Provide visual confirmation to the user
                notification.success({
                    message: 'Linked Successfully',
                    description: `Linked "${metadata.title}" to "${selectedConflict.title}"`
                });
            } catch (error) {
                console.error('Error linking metadata:', error);
                notification.error({
                    message: 'Link Failed',
                    description: 'An error occurred while linking the metadata.'
                });
            }
        } else {
            notification.error({
                message: 'Link Failed',
                description: 'Could not link metadata. Selected conflict is missing a reference ID.'
            });
        }
    };

    const handleSaveAll = async () => {
        try {
            // Validate titleConflictData exists and is an array
            if (!Array.isArray(titleConflictsHook.titleConflictData)) {
                console.error("titleConflictData is not an array", titleConflictsHook.titleConflictData);
                notification.error({
                    message: 'Processing Error',
                    description: 'Unable to process data due to an invalid format. Please refresh the page and try again.'
                });
                return;
            }
            
            // Filter and map, with additional safety checks
            const updatedTitleConflictData = titleConflictsHook.titleConflictData
                .filter(conflict => conflict && conflict.ref_id && linkedMetadata[conflict.ref_id])
                .map(conflict => {
                    const linkedData = linkedMetadata[conflict.ref_id] || {};
                    return {
                        ...conflict,
                        linked_metadata: linkedData.ref_id || '',
                        linked_metadata_title: linkedData.title || '',
                        resolution_operation: linkedData.operation || '',
                        language: linkedData.language || '',
                    };
                });

            if (updatedTitleConflictData.length === 0) {
                notification.info({
                    message: 'No Changes',
                    description: 'No changes detected. Nothing to save.'
                });
                return;
            }

            // Mark all these records as processing
            updatedTitleConflictData.forEach(conflict => {
                if (conflict && conflict.id) {
                    markAsProcessing(conflict.id);
                }
            });

            console.log('updatedTitleConflictData: ', updatedTitleConflictData);
            
            // Log the request payload before sending
            const requestPayload = {
                titleConflicts: updatedTitleConflictData,
                userEmail: currentUserEmail || ''  // Default to empty string if null
            };
            console.log('Request payload being sent: ', requestPayload);
            
            await saveAllTitleConflicts(updatedTitleConflictData, currentUserEmail || '');
            notification.success({
                message: 'Save Successful',
                description: `${updatedTitleConflictData.length} title conflict${updatedTitleConflictData.length === 1 ? '' : 's'} have been updated successfully.`
            });
        } catch (error) {
            console.error("Failed to save title conflicts", error);
            notification.error({
                message: 'Save Failed',
                description: 'An error occurred while saving title conflicts. Please try again.'
            });
        }
    };

    // Function to handle alternate title creation processing
    const handleAlternateTitleProcessing = (record: UnmatchedAssetInterface) => {
        if (record.id) {
            markAsProcessing(record.id);
        }
    };

    // Function to handle remove/mark as promo processing
    const handleRemoveRecordProcessing = (record: UnmatchedAssetInterface) => {
        if (record.id) {
            markAsProcessing(record.id);
        }
    };

    return (
        <>
            <Row gutter={8} align="top">
                <Col span={9}>
                    <UnmatchedAssetsTable
                        height={height * 0.85}  
                        isLoading={titleConflictsHook.isLoading}
                        titleConflictData={titleConflictsHook.titleConflictData}
                        selectedConflict={selectedConflict}
                        pagination={titleConflictsHook.pagination}
                        titleConflictColumns={titleConflictsHook.titleConflictColumns}
                        onConflictSelect={handleConflictSelect}
                        linkedMetadata={linkedMetadata}
                        onRemoveRecord={titleConflictsHook.showRemoveModal}
                        processingRecords={processingRecords}
                        onAlternateTitleCreated={handleAlternateTitleProcessing}
                    />
                </Col>
                <Col span={15}>
                    <MetadataPool
                        selectedConflict={selectedConflict}
                        onMetadataLink={handleMetadataLink}
                        allMetadataData={allMetadataData}
                        setAllMetadataData={setAllMetadataData}
                        loading={metadataLoading}
                        onSaveAll={handleSaveAll}
                    />
                </Col>
            </Row>
            
            <RemoveAssetModal
                isOpen={titleConflictsHook.isRemoveModalOpen}
                record={titleConflictsHook.recordToRemove}
                isLoading={titleConflictsHook.isLoading}
                onConfirm={() => {
                    if (titleConflictsHook.recordToRemove?.id) {
                        handleRemoveRecordProcessing(titleConflictsHook.recordToRemove);
                    }
                    return titleConflictsHook.handleDeleteConfirm();
                }}
                onCancel={titleConflictsHook.hideRemoveModal}
            />
        </>
    );
};
export default AssetMatchingPage;