import React from 'react';
import type { SortOrder } from "antd/es/table/interface";
import { ColumnType } from "antd/lib/table";
import moment from "moment-timezone";
import { TextWithCopyButton } from "../cue-points/hooks/cuePointColumns";

export const lastModifiedCol = (defaultSortOrder?: SortOrder): ColumnType<any> => ({
	title: "Last Modified",
	dataIndex: "updated_at",
	key: "updated_at",
	defaultSortOrder: defaultSortOrder as SortOrder,
	width: "140px",
	sorter: (a: any, b: any) =>
		moment(b.updated_at ? b.updated_at : b.created_at).valueOf() -
		moment(a.updated_at ? a.updated_at : a.created_at).valueOf(),
	render: (value, records) => {
		const lastModifiedDate = value?.length ? value : records.created_at;
		const date = moment(lastModifiedDate).tz("America/New_York").format("M/D/YYYY hh:mmA z");
		return date;
	},
});

export const refIdColumn = {
	title: "Ref ID",
	dataIndex: "ref_id",
	key: "ref_id",
	render: (value: string) => <TextWithCopyButton value={value} />,
};
