import { Form, notification } from "antd";
import { useState } from "react";
import { UnmatchedAssetInterface } from "../interfaces";
import { saveUpdateAlternateTitle } from "./utils";
import { AlternateTitleSubmissionData } from "../interfaces";
import { getEmailFromSessionStorage } from "../../utils/session_utils";


/**
 * Custom hook for handling alternate title form operations
 */
const useAlternateTitles = () => {
    const [form] = Form.useForm();
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentRecord, setCurrentRecord] = useState<UnmatchedAssetInterface | null>(null);
    const [editId, setEditId] = useState<string | undefined>(undefined);
    const userEmail = getEmailFromSessionStorage();
    
    /**
     * Open the alternate title form with a record's data
     */
    const openForm = (record: UnmatchedAssetInterface) => {
        setCurrentRecord(record);
        
        // Create form values object with required fields
        const formValues: any = {
            alternate_title_ref_id: record.ref_id,
            alternate_title: record.title,
        };
        
        // Only add optional fields if they exist in the record
        if (record.content_provider) {
            formValues.content_provider = record.content_provider;
        }
        
        if (record.language) {
            formValues.language = record.language;
        }
        
        if (record.notes) {
            formValues.notes = record.notes;
        }
        
        form.setFieldsValue(formValues);
        setEditId(record.id);
        setIsFormOpen(true);
    };
    
    /**
     * Cancel and close the form
     */
    const handleCancel = () => {
        setIsFormOpen(false);
        form.resetFields();
        setCurrentRecord(null);
        setEditId(undefined);
    };
    
    /**
     * Save the form data
     */
    const saveAlternateTitle = async () => {
        setIsLoading(true);
        try {
            // Validate form fields
            const values = await form.validateFields();
            
            // Transform form values to match API expectations
            const submissionData: AlternateTitleSubmissionData = {
                alternate_title_ref_id: values.alternate_title_ref_id,
                alternate_title: values.alternate_title,
                notes: values.notes
            };

            // Call the API function
            await saveUpdateAlternateTitle(submissionData, editId, userEmail);
            
            // Show success message
            notification.success({
                message: 'Success',
                description: `Alternate title successfully ${editId ? 'updated' : 'created'}`
            });
            
            // Store current record before resetting it
            const recordToReturn = currentRecord;
            
            // Close modal and reset form
            setIsFormOpen(false);
            form.resetFields();
            setCurrentRecord(null);
            setEditId(undefined);
            
            return recordToReturn;
        } catch (error) {
            console.error("Failed to save alternate title:", error);
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        form,
        isFormOpen,
        setIsFormOpen,
        isLoading,
        setIsLoading,
        currentRecord,
        editId,
        openForm,
        handleCancel,
        saveAlternateTitle
    };
};

export default useAlternateTitles;