import React, { useState, useEffect } from "react";
import { Card, Input, Button, Alert, Spin, Statistic, Progress, Descriptions, Badge, Row, Col, Divider, Table, Tag } from "antd";
import { ReloadOutlined, SearchOutlined, CheckCircleOutlined, LineChartOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { authAxios } from "../../components/utils/session_utils";

const { Search } = Input;

// Job progress monitor props
export interface JobProgressMonitorProps {
  jobId: string;
  refreshInterval?: number;
  onClose?: () => void;
  compact?: boolean;
  // New prop to determine if we should show recent jobs when no job ID is provided
  showRecentJobs?: boolean;
}

// Helper function to format elapsed time in a human-readable way
function formatElapsedTime(startDate: Date, endDate: Date = new Date()): string {
  const diffMs = Math.max(0, endDate.getTime() - startDate.getTime());
  const seconds = Math.floor(diffMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  
  if (hours > 0) {
    return `${hours}h ${minutes % 60}m`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds % 60}s`;
  } else {
    return `${seconds}s`;
  }
}

// Helper function to format time remaining
function formatTimeRemaining(milliseconds: number): string {
  if (milliseconds <= 0) return "Completing soon...";
  
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  
  if (hours > 0) {
    return `${hours}h ${minutes % 60}m remaining`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds % 60}s remaining`;
  } else {
    return `${seconds}s remaining`;
  }
}

const JobProgressMonitor: React.FC<JobProgressMonitorProps> = ({ 
  jobId: propJobId,
  refreshInterval: propRefreshInterval = 5000,
  onClose,
  compact = false,
  showRecentJobs = true
}) => {
  // State for the search input (used only in standalone mode)
  const [searchJobId, setSearchJobId] = useState<string>("");
  
  // State for the actual job ID being monitored
  const [activeJobId, setActiveJobId] = useState<string>(propJobId || "");
  
  const [jobData, setJobData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date());
  
  // Calculate processing rate and estimated time
  const [processingRate, setProcessingRate] = useState<number | null>(null);
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  
  // NEW: State for recent jobs
  const [recentJobs, setRecentJobs] = useState<any[]>([]);
  const [loadingRecentJobs, setLoadingRecentJobs] = useState<boolean>(false);
  const [recentJobsError, setRecentJobsError] = useState<string | null>(null);
  
  // NEW: Function to fetch recent jobs
  const fetchRecentJobs = async () => {
    setLoadingRecentJobs(true);
    setRecentJobsError(null);
    
    try {
      console.log("Fetching recent jobs...");
      
      const response = await authAxios({
        method: "GET",
        url: `${process.env.REACT_APP_BACK_END_API}/salesforce/operation-status/recent`,
        params: {
          limit: 20,
          type: 'spot-data' // Only get spot data upload jobs if needed
        }
      });
      
      console.log("Recent jobs response:", response);
      
      if (response.data.success) {
        // Transform job data for better display
        const formattedJobs = response.data.jobs.map((job: any) => {
          // Ensure we have a valid status object
          const statusValue = typeof job.status === 'object' ? job.status : 
            (typeof job.status === 'string' ? { status: job.status } : { status: 'UNKNOWN' });
          
          // Determine status type for styling
          let statusType = "default";
          const status = typeof statusValue === 'string' ? statusValue : statusValue.status;
          
          if (status === "COMPLETED") statusType = "success";
          else if (status === "FAILED" || status === "CANCELLED") statusType = "error";
          else if (status === "COMPLETED_WITH_ERRORS") statusType = "warning";
          else if (status === "QUEUED" || status.includes("PROCESSING")) statusType = "processing";
          
          // Merge the job object with status properties
          return {
            ...job,
            ...statusValue,
            statusDisplay: status,
            statusType,
            displayName: job.operationId || 'Unknown Job',
            formattedStartTime: job.startTime ? new Date(job.startTime).toLocaleString() : 'Unknown'
          };
        });
        
        setRecentJobs(formattedJobs);
        console.log(`Successfully loaded ${formattedJobs.length} recent jobs`);
      } else {
        console.error("Failed to fetch recent jobs. Server response:", response.data);
        setRecentJobsError(response.data.error || "Failed to fetch recent jobs");
      }
    } catch (error: any) {
      console.error("Error fetching recent jobs:", error);
      
      // Detailed error handling
      if (error.response) {
        setRecentJobsError(`Server error: ${error.response.status} - ${error.response.data?.error || 'Unknown error'}`);
      } else if (error.request) {
        setRecentJobsError("Network error: No response received from server");
      } else {
        setRecentJobsError(`Error: ${error.message}`);
      }
    } finally {
      setLoadingRecentJobs(false);
    }
  };
  
  // Handle search from the search bar
  const handleSearch = (value: string) => {
    if (value) {
      setActiveJobId(value);
      fetchJobStatus(value);
      setupRefreshInterval(value, propRefreshInterval);
    }
  };
  
  // Set up the refresh interval
  const setupRefreshInterval = (id: string, interval: number) => {
    // Clear any existing interval
    if (intervalId) {
      clearInterval(intervalId);
    }
    
    // Set up auto-refresh
    const newIntervalId = setInterval(() => {
      fetchJobStatus(id);
    }, interval);
    
    setIntervalId(newIntervalId);
  };

  // Fetch job status
  const fetchJobStatus = async (id: string = activeJobId) => {
    if (!id || typeof id !== 'string' || id.length > 100 || id.includes('<')) {
      console.error("Invalid job ID:", id);
      setError("Invalid job ID format");
      setLoading(false);
      return;
    }
    

    // Ensure API base URL is present
    const baseUrl = process.env.REACT_APP_BACK_END_API || '';
    if (!baseUrl) {
      console.error("API base URL is not defined");
      setError("API configuration error");
      setLoading(false);
      return;
    }
    
    setLoading(true);
    try {
      const fullUrl = `${process.env.REACT_APP_BACK_END_API}/salesforce/operation-status/${id}`;

      // Add a unique query parameter to track this specific request
      const response = await authAxios({
        method: "GET",
        url: `${fullUrl}?debug=true`,
      });
      
      // Store the previous data for rate calculations
      const prevData = jobData;
      
      if (response.data && response.data.status) {
        let newData;
        
        // Parse status if it's a string
        if (typeof response.data.status === 'string') {
          newData = { status: response.data.status };
        } else {
          newData = response.data.status;
        }
        
        setJobData(newData);
        setError(null);
        
        // Calculate processing rate if we have necessary data
        if (prevData && newData && 
            prevData.processedRecords !== undefined && 
            newData.processedRecords !== undefined && 
            prevData.processedRecords !== newData.processedRecords) {
          
          const recordsProcessed = newData.processedRecords - prevData.processedRecords;
          const timeElapsed = (new Date().getTime() - lastUpdated.getTime()) / 1000; // in seconds
          
          if (timeElapsed > 0) {
            // Records per second
            const rate = recordsProcessed / timeElapsed;
            setProcessingRate(rate);
            
            // Estimate time remaining
            if (newData.totalRecords && rate > 0) {
              const remainingRecords = newData.totalRecords - newData.processedRecords;
              const timeRemainingSeconds = remainingRecords / rate;
              setTimeRemaining(timeRemainingSeconds * 1000); // convert to ms
            }
          }
        }
        
        setLastUpdated(new Date());
      } else {
        setError("Invalid response format");
      }
    } catch (err: any) {
      setError(err.message || "Failed to fetch job status");
    } finally {
      setLoading(false);
    }
  };
  
  // Initialize from props when component mounts or propJobId changes
  useEffect(() => {
    if (propJobId) {
      setActiveJobId(propJobId);
      fetchJobStatus(propJobId);
      setupRefreshInterval(propJobId, propRefreshInterval);
    } else if (showRecentJobs) {
      // If no job ID is provided but we should show recent jobs, fetch them
      fetchRecentJobs();
    }
  }, [propJobId, propRefreshInterval, showRecentJobs]);
  
  // Cleanup interval on unmount
  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  // Get status badge
  const getStatusBadge = () => {
    if (!jobData) return <Badge status="default" text="Unknown" />;
    
    const status = jobData.status.toUpperCase();
    
    if (status === "COMPLETED") {
      return <Badge status="success" text="Completed" />;
    } else if (status === "FAILED") {
      return <Badge status="error" text="Failed" />;
    } else if (status === "QUEUED") {
      return <Badge status="warning" text="Queued" />;
    } else if (status.includes("PROCESSING") || status === "RUNNING") {
      return <Badge status="processing" text="Processing" />;
    } else {
      return <Badge status="default" text={status} />;
    }
  };
  
  // NEW: Function to select a job from the recent jobs list
  const selectJob = (jobId: string) => {
    setSearchJobId("");
    setActiveJobId(jobId);
    fetchJobStatus(jobId);
    setupRefreshInterval(jobId, propRefreshInterval);
  };
  
  // NEW: Render the recent jobs table
  const renderRecentJobs = () => {
    // Define table columns
    const columns = [
      {
        title: 'Job ID',
        dataIndex: 'operationId',
        key: 'operationId',
        render: (text: string) => {
          // Show only the last part of the job ID for better readability
          const shortId = text.split('_').pop();
          return (
            <Button type="link" onClick={() => selectJob(text)}>
              {shortId}
            </Button>
          );
        },
      },
      {
        title: 'Status',
        key: 'status',
        render: (_: any, record: any) => {
          let color = 'default';
          const status = record.statusDisplay || record.status;
          
          if (status === 'COMPLETED') color = 'success';
          else if (status === 'FAILED') color = 'error';
          else if (status.includes('PROCESSING')) color = 'processing';
          else if (status === 'QUEUED') color = 'warning';
          
          return <Tag color={color}>{status}</Tag>;
        },
      },
      {
        title: 'Records',
        key: 'records',
        render: (_: any, record: any) => {
          return record.processedRecords !== undefined && record.totalRecords !== undefined ? 
            `${record.processedRecords} / ${record.totalRecords}` : 
            '-';
        },
      },      
      {
        title: 'Started',
        key: 'startTime',
        render: (_: any, record: any) => record.formattedStartTime || 
          (record.startTime ? new Date(record.startTime).toLocaleString() : '-'),
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_: any, record: any) => (
          <Button 
            type="primary" 
            size="small" 
            onClick={() => selectJob(record.operationId)}
          >
            Monitor
          </Button>
        ),
      },
    ];

    if (loadingRecentJobs && recentJobs.length === 0) {
      return (
        <div style={{ textAlign: 'center', padding: 20 }}>
          <Spin />
          <div style={{ marginTop: 10 }}>Loading recent jobs...</div>
        </div>
      );
    }
    
    if (recentJobsError) {
      return (
        <Alert 
          message="Error loading recent jobs" 
          description={recentJobsError}
          type="error" 
          showIcon 
          action={
            <Button size="small" onClick={fetchRecentJobs}>
              Retry
            </Button>
          }
        />
      );
    }
    
    if (recentJobs.length === 0) {
      return (
        <Alert
          message="No recent jobs found"
          description="Upload a file to create a new job or enter a job ID manually above."
          type="info"
          showIcon
        />
      );
    }

    return (
      <Card 
        title="Recent Jobs" 
        extra={
          <Button 
            icon={<ReloadOutlined />} 
            onClick={fetchRecentJobs}
            loading={loadingRecentJobs}
          >
            Refresh
          </Button>
        }
      >
        <Table 
          dataSource={recentJobs} 
          columns={columns} 
          rowKey="operationId"
          loading={loadingRecentJobs}
          pagination={false}
          size="small"
        />
      </Card>
    );
  };
  
  // Render compact view if requested
  if (compact) {
    return (
      <Card bordered>
        {loading && !jobData ? (
          <Spin />
        ) : error ? (
          <Alert message={error} type="error" showIcon />
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
              <div>Status: {getStatusBadge()}</div>
              <Button 
                icon={<ReloadOutlined />} 
                onClick={() => fetchJobStatus()}
                size="small"
              >
                Refresh
              </Button>
            </div>
            
            {jobData?.percentComplete !== undefined && (
              <Progress 
                percent={jobData.percentComplete} 
                status={jobData.status?.includes("FAILED") ? "exception" : "active"}
              />
            )}
            
            {jobData?.message && (
              <div style={{ marginTop: 10 }}>
                <Alert message={jobData.message} type="info" showIcon />
              </div>
            )}
          </>
        )}
      </Card>
    );
  }
  
  // Standard full view
  return (
    <div style={{ maxWidth: 800, margin: '0 auto', padding: '20px' }}>
      <h1>Salesforce Batch Job Monitor</h1>
      
      <Card bordered>
        <Search
          placeholder="Enter Job ID"
          enterButton={<Button type="primary" icon={<SearchOutlined />}>Monitor</Button>}
          size="large"
          value={searchJobId}
          onChange={(e) => setSearchJobId(e.target.value)}
          onSearch={handleSearch}
          style={{ marginBottom: 20 }}
        />
        
        {error && (
          <Alert
            message="Error"
            description={error}
            type="error"
            showIcon
            style={{ marginBottom: 20 }}
          />
        )}
        
        {loading && !jobData && activeJobId && (
          <div style={{ textAlign: 'center', padding: 20 }}>
            <Spin size="large" />
            <div style={{ marginTop: 10 }}>Loading job details...</div>
          </div>
        )}
        
        {jobData && (
          <div>
            <Card 
              title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>Job: {activeJobId}</span>
                  {getStatusBadge()}
                </div>
              }
              extra={
                <Button 
                  icon={<ReloadOutlined />} 
                  onClick={() => fetchJobStatus()}
                  size="small"
                >
                  Refresh
                </Button>
              }
              bordered
            >
              {jobData.message && (
                <Alert
                  message={jobData.message}
                  type="info"
                  showIcon
                  style={{ marginBottom: 15 }}
                />
              )}
              
              {jobData.percentComplete !== undefined && (
                <Progress 
                  percent={jobData.percentComplete} 
                  status={jobData.status.includes("FAILED") ? "exception" : "active"}
                  strokeWidth={15}
                  style={{ marginBottom: 20 }}
                />
              )}
              
              <Row gutter={[16, 16]}>
                {jobData.processedRecords !== undefined && jobData.totalRecords !== undefined && (
                  <Col span={8}>
                    <Statistic 
                      title="Records Processed" 
                      value={jobData.processedRecords} 
                      suffix={`/ ${jobData.totalRecords}`}
                      valueStyle={{ color: '#3f8600' }}
                      prefix={<CheckCircleOutlined />}
                    />
                  </Col>
                )}
                
                {processingRate !== null && (
                  <Col span={8}>
                    <Statistic 
                      title="Processing Speed" 
                      value={(processingRate * 60).toFixed(0)} 
                      suffix="records/min"
                      valueStyle={{ color: '#1890ff' }}
                      prefix={<LineChartOutlined />}
                    />
                  </Col>
                )}
                
                {jobData.currentBatch !== undefined && (
                  <Col span={8}>
                    <Statistic 
                      title="Current Batch" 
                      value={jobData.currentBatch} 
                      suffix={jobData.batchesTotal ? `/ ${jobData.batchesTotal}` : ''}
                      valueStyle={{ color: '#722ed1' }}
                      prefix={<ClockCircleOutlined />}
                    />
                  </Col>
                )}
              </Row>
              
              <Divider />
              
              <Descriptions title="Job Details" bordered column={1} size="small">
                <Descriptions.Item label="Status">{jobData.status}</Descriptions.Item>
                {jobData.startTime && (
                  <Descriptions.Item label="Start Time">
                    {new Date(jobData.startTime).toLocaleString()}
                  </Descriptions.Item>
                )}
                {jobData.endTime && (
                  <Descriptions.Item label="End Time">
                    {new Date(jobData.endTime).toLocaleString()}
                  </Descriptions.Item>
                )}
                {jobData.startTime && !jobData.endTime && (
                  <Descriptions.Item label="Elapsed Time">
                    {formatElapsedTime(new Date(jobData.startTime))}
                  </Descriptions.Item>
                )}
                {timeRemaining !== null && (
                  <Descriptions.Item label="Estimated Time Remaining">
                    {formatTimeRemaining(timeRemaining)}
                  </Descriptions.Item>
                )}
                {jobData.successCount !== undefined && jobData.failureCount !== undefined && (
                  <Descriptions.Item label="Success/Failure">
                    {jobData.successCount} successful, {jobData.failureCount} failed
                  </Descriptions.Item>
                )}
              </Descriptions>
              
              <div style={{ marginTop: 20, fontSize: 12, color: '#999', textAlign: 'right' }}>
                Last updated: {lastUpdated.toLocaleTimeString()}
              </div>
            </Card>
          </div>
        )}
        
        {!activeJobId && showRecentJobs && (
          <div style={{ marginTop: 20 }}>
            {renderRecentJobs()}
          </div>
        )}
      </Card>
    </div>
  );
};

export default JobProgressMonitor;