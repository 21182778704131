import React, { useEffect, useState } from "react";
import { Form, PaginationProps, message } from "antd";
import { useOutletContext } from "react-router-dom";
import { initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import { PromoData } from "../promos";
import { authAxios } from "../../utils/session_utils";
import { makeKeyInLowerCaseHelper, SETTINGS_DATA, updateUserPageSizeSettings } from "../../utils";
import getPromoColumns from "./columns";

const promoRoute = "promos";

const usePromosHook = () => {
	const [form] = Form.useForm();
	const { user, setUser }: any = useOutletContext();
	const [pagination, setPagination] = useState<PaginationProps>({
		...initialPaginationObj,
		pageSize: user.page_sizes?.promos ?? 10,
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [promoData, setPromoData] = useState<any[]>([]);
	const [promoEditId, setPromoEditId] = useState<string>();
	const [isPromoFormOpen, setIsPromoFormOpen] = useState<boolean>(false);

	useEffect(() => {
		getPromosDataHandler({});
	}, [pagination.current, pagination.pageSize]);

	const handleTableChange = (pagination: PaginationProps, _: any, sorter: any, { action }: any) => {
		switch (action) {
			case "paginate": {
				if (pagination.pageSize !== user.page_sizes.cuepoints) {
					const updatedSettingsData: SETTINGS_DATA = {
						user_id: user.user_id,
						page_sizes: { ...user.page_sizes, promos: pagination.pageSize },
					};
					setUser({ ...user, ...updatedSettingsData });
					updateUserPageSizeSettings(updatedSettingsData);
				}
				setIsLoading(true);
				getPromosDataHandler({ paginationData: pagination });
				break;
			}
		}
	};

	const getPromosDataHandler = async ({ paginationData }: { paginationData?: PaginationProps }) => {
		try {
			const config: any = {
				method: "GET",
				url: `${
					process.env.REACT_APP_BACK_END_API
				}/snowflake/${promoRoute}/?pagination=${JSON.stringify(paginationData ?? pagination)}`,
				headers: {
					"Content-Type": "application/json",
				},
			};
			setIsLoading(true);
			const response = await authAxios(config);
			const convertedObject = await makeKeyInLowerCaseHelper(response.data.promos);
			setPromoData(convertedObject);
			setPagination({ ...paginationData, total: response.data.totals });
			console.log("🚀 ~ getPromosDataHandler ~ response:", response);
		} catch (error) {
			console.error(error);
			message.error("Something went wrong!");
		} finally {
			setIsLoading(false);
		}
	};

	const editHandler = (record: any) => {
		try {
			setPromoEditId(record.promo_id);
			form.setFieldsValue({ ...record });
			setIsPromoFormOpen(true);
		} catch (error) {
			console.error(error);
		}
	};

	const createUpdateCuePoint = async () => {
		try {
			const values = await form.validateFields();
			const data: any = {
				...values,
			};
			if (promoEditId) {
				data["promoEditId"] = promoEditId;
			}
			const config = {
				method: promoEditId ? "PUT" : "POST",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/promos`,
				headers: {
					"Content-Type": "application/json",
				},
				data: JSON.stringify(data),
			};
			setIsLoading(true);
			const response = await authAxios(config);
			if (response.status === 200) {
				message.success(response.data.message);
				onCancelPromoForm();
				getPromosDataHandler({});
			}
		} catch (error: any) {
			setIsLoading(false);
			console.error(error);
			const errorMsg = error?.response?.data?.message ?? null;
			errorMsg && message.error(errorMsg);
		}
	};

	const deletePromoData = async (promoID: string) => {
		try {
			setIsLoading(true);
			const config = {
				method: "DELETE",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/promos/${promoID}`,
			};
			const response = await authAxios(config);

			if (response.status === 200) {
				message.success(response.data.message);
				getPromosDataHandler({});
			}
		} catch (error) {
			console.error(error);
			message.error("Something went wrong");
			setIsLoading(false);
		}
	};

	const onCancelPromoForm = () => {
		setIsPromoFormOpen(false);
		form.resetFields();
		setPromoEditId(undefined);
	};
	return {
		form,
		pagination,
		isLoading,
		editHandler,
		createUpdateCuePoint,
		handleTableChange,
		promoData,
		isPromoFormOpen,
		setIsPromoFormOpen,
		promoEditId,
		onCancelPromoForm,
		promoColumns: getPromoColumns(deletePromoData, isLoading),
	};
};

export default usePromosHook;
