import React, { useState } from "react";
import { Upload, Button, message, Card, Typography, Alert, Steps, Row, Col, Modal, Tabs } from "antd";
import { UploadOutlined, CheckCircleOutlined, FileTextOutlined, LineChartOutlined } from "@ant-design/icons";
import { authAxios } from "../../components/utils/session_utils";
import JobProgressMonitor from "./job_monitor";

const { Title, Text } = Typography;
const { Step } = Steps;
const { TabPane } = Tabs;

const BundleSpotDataUpload: React.FC = () => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadComplete, setUploadComplete] = useState<boolean>(false);
  const [jobNumber, setJobNumber] = useState<string>("");
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [showMonitor, setShowMonitor] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("upload");
  const [monitorJobId, setMonitorJobId] = useState<string>("");
  
  // Your existing functions
  const handleBeforeUpload = (file: File) => {
    // Existing implementation
    const isCSV = file.type === "text/csv";
    if (!isCSV) {
      message.error("Only CSV files are allowed!");
      return Upload.LIST_IGNORE;
    }
    
    setFileList([{
      originFileObj: file,
      name: file.name,
      status: 'done',
    }]);
    
    setCurrentStep(1);
    return false;
  };

  const handleOnChange = (info: any) => {
    // Existing implementation
    setFileList(info.fileList);
    if (info.fileList.length === 0) {
      setCurrentStep(0);
    }
  };
  
  const handleUpload = async () => {
    // Existing implementation
    if (!fileList.length) {
      message.error("No file selected");
      return;
    }
  
    setLoading(true);
    const hideLoading = message.loading("Uploading CSV...", 0);
  
    try {
      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append('file', fileList[0].originFileObj);
  
      // Use authAxios instead of fetch
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_BACK_END_API}/salesforce/upload_spot_data_file`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      
      const response = await authAxios(config);
      
      if (!response.data.success) {
        throw new Error(response.data.error || "Upload failed");
      }
  
      setLoading(false);
      hideLoading();
      setUploadComplete(true);
      setCurrentStep(2);
      
      // Check if jobId exists in the response
      if (response.data.jobId) {
        setJobNumber(response.data.jobId);
        setMonitorJobId(response.data.jobId); // Also set for monitor tab
      }
      
      message.success("CSV uploaded successfully!");
      
    } catch (error: any) {
      console.error("Upload error:", error);
      setLoading(false);
      hideLoading();
      message.error(`Upload failed: ${error.message || "Unknown error"}`);
    }
  };
  
  const resetForm = () => {
    // Existing implementation
    setFileList([]);
    setUploadComplete(false);
    setJobNumber("");
    setCurrentStep(0);
  };
  
  // New functions for tab handling
  const handleTabChange = (key: string) => {
    setActiveTab(key);
    
    // If switching to monitor tab and we have a job number, use it
    if (key === "monitor" && jobNumber && !monitorJobId) {
      setMonitorJobId(jobNumber);
    }
  };
  
  // Your existing render methods
  const renderUploadInterface = () => (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Steps current={currentStep} style={{ marginBottom: 24 }}>
            <Step title="Select File" icon={<FileTextOutlined />} />
            <Step title="Review" />
            <Step title="Complete" icon={<CheckCircleOutlined />} />
          </Steps>
        </Col>
      </Row>
      
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Upload
            beforeUpload={handleBeforeUpload}
            onChange={handleOnChange}
            fileList={fileList}
            maxCount={1}
            onRemove={() => setFileList([])}
          >
            <Button 
              icon={<UploadOutlined />} 
              disabled={loading || uploadComplete}
              size="large"
            >
              Select CSV
            </Button>
          </Upload>
        </Col>
      </Row>
      
      {fileList.length > 0 && (
        <Row style={{ marginTop: 16 }}>
          <Col span={24}>
            <Alert
              message="File Selected"
              description={`File "${fileList[0].name}" is ready to upload.`}
              type="info"
              showIcon
            />
          </Col>
        </Row>
      )}
      
      <Row style={{ marginTop: 16 }}>
        <Col span={24}>
          <Button
            type="primary"
            onClick={handleUpload}
            disabled={!fileList.length || loading || uploadComplete}
            loading={loading}
            size="large"
            block
          >
            {loading ? "Uploading..." : "Upload CSV"}
          </Button>
        </Col>
      </Row>
    </>
  );

  const renderConfirmation = () => (
    <Row gutter={[16, 16]}>
      <Col span={24} style={{ textAlign: 'center' }}>
        <CheckCircleOutlined style={{ fontSize: 64, color: '#52c41a', marginBottom: 16 }} />
        <Title level={3}>Thank you for uploading</Title>
        <Text>Your file has been uploaded and is being processed.</Text>
        
        <Alert
          message="Upload Successful"
          description={`Job Number: ${jobNumber}`}
          type="success"
          showIcon
          style={{ marginTop: 16, marginBottom: 16 }}
        />
        
        <div style={{ marginTop: 16, marginBottom: 24 }}>
          <JobProgressMonitor 
            jobId={jobNumber} 
            compact={true} 
            refreshInterval={5000} 
          />
        </div>
        
        <div style={{ marginTop: 16 }}>
          <Button 
            type="primary" 
            icon={<LineChartOutlined />} 
            onClick={() => setActiveTab("monitor")} 
            style={{ marginRight: 16 }}
          >
            Monitor Job
          </Button>
          
          <Button onClick={resetForm}>
            Upload Another File
          </Button>
        </div>
      </Col>
    </Row>
  );

  // Updated job monitor render method (simplified to use enhanced JobProgressMonitor)
  const renderJobMonitor = () => (
    <div>
      <JobProgressMonitor 
        jobId={monitorJobId} 
        refreshInterval={5000}
        showRecentJobs={true}
      />
    </div>
  );

  // Updated main render with tabs
  return (
    <>
      <Card 
        title={<Title level={4}>Bundle Spot Data Management</Title>}
        style={{ maxWidth: 900, margin: '0 auto', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}
        bordered={true}
      >
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="Upload CSV" key="upload">
            <div style={{ maxWidth: 600, margin: '0 auto' }}>
              {uploadComplete ? renderConfirmation() : renderUploadInterface()}
            </div>
          </TabPane>
          <TabPane tab="Job Monitor" key="monitor">
            {renderJobMonitor()}
          </TabPane>
        </Tabs>
      </Card>
      
      <Modal
        title="Job Progress Monitor"
        open={showMonitor}
        onCancel={() => setShowMonitor(false)}
        footer={null}
        width={700}
      >
        {jobNumber && <JobProgressMonitor jobId={jobNumber} onClose={() => setShowMonitor(false)} />}
      </Modal>
    </>
  );
}

export default BundleSpotDataUpload;