import React from "react";
import { Modal, Button, Typography } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { UnmatchedAssetInterface } from '../interfaces';

interface Props {
    isOpen: boolean;
    record: UnmatchedAssetInterface | null;
    isLoading: boolean;
    onConfirm: () => Promise<void>;
    onCancel: () => void;
}


const RemoveAssetModal: React.FC<Props> = ({
    isOpen,
    record,
    isLoading,
    onConfirm,
    onCancel
}) => {
    return (
        <Modal
            title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ExclamationCircleOutlined style={{ color: '#faad14', marginRight: 8 }} />
                    <span>Remove Record</span>
                </div>
            }
            open={isOpen}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button 
                    key="remove" 
                    type="primary" 
                    danger 
                    loading={isLoading}
                    onClick={onConfirm}
                >
                    Remove this record from the viewership data
                </Button>
            ]}
            centered
            maskClosable={false}
        >
            <Typography.Paragraph>
                Are you sure you want to remove this record? This action cannot be undone.
            </Typography.Paragraph>
            {record && (
                <div style={{ background: '#f5f5f5', padding: 12, borderRadius: 4, marginTop: 12 }}>
                    <Typography.Text strong>
                        Title: {record.title}
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                        Notes: {record.notes}
                    </Typography.Text>
                </div>
            )}
        </Modal>
    );
};

export default RemoveAssetModal;