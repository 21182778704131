import React, { useState } from "react";
import { Row, Col, Table, Dropdown, Button, Form, Tooltip } from "antd";
import { ComponentTitle } from "../../custom-components";
import { UnmatchedAssetInterface } from '../interfaces';
import { TableRowSelection, TablePaginationConfig } from "antd/es/table/interface";
import styled from 'styled-components';
import { SettingOutlined, SyncOutlined } from '@ant-design/icons';
import AlternateTitleForm from "../modal/AlternateTitleForm";
import useAlternateTitles from "../hooks/useAlternateTitles";

const StyledDiv = styled.div`
  .ant-table-row {
    background-color: white;
  }
  .ant-table-row-selected {
    background-color: #e6f7ff;
  }
  .ant-table-row:hover {
    background-color: #f5f5f5;
  }
  .ant-table-row-selected:hover {
    background-color: #e6f7ff;
  }
  .processing-row {
    opacity: 0.6;
    background-color: #f0f0f0 !important;
    pointer-events: none;
  }
`;

interface UnmatchedAssetsProps {
    height: number;
    isLoading: boolean;
    titleConflictData: UnmatchedAssetInterface[];
    titleConflictColumns: any[];
    pagination: any;
    onConflictSelect: (record: UnmatchedAssetInterface) => void;
    selectedConflict: UnmatchedAssetInterface | null;
    linkedMetadata: { [key: string]: { ref_id: string, operation: string, language: string, title: string } };
    onRemoveRecord?: (record: UnmatchedAssetInterface) => void;
    processingRecords?: { [key: string]: boolean };
    onAlternateTitleCreated?: (record: UnmatchedAssetInterface) => void;
}

const UnmatchedAssets: React.FC<UnmatchedAssetsProps> = ({
    height,
    isLoading,
    titleConflictData,
    titleConflictColumns,
    onConflictSelect,
    selectedConflict,
    linkedMetadata,
    onRemoveRecord,
    processingRecords = {},
    onAlternateTitleCreated
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    
    // Use the alternate titles hook
    const alternateTitles = useAlternateTitles();

    // Create a type-safe version of the function
    const getCheckboxProps = (record: UnmatchedAssetInterface) => {
        const result: { disabled?: boolean; name?: string } = {};
        
        if (record && record.id) {
            result.disabled = processingRecords[record.id] === true;
            result.name = record.id;
        } else {
            result.disabled = false;
        }
        
        return result;
    };

    const rowSelection: TableRowSelection<UnmatchedAssetInterface> = {
        type: 'radio',
        selectedRowKeys: selectedConflict && selectedConflict.id ? [selectedConflict.id] : [],
        onChange: (selectedRowKeys: React.Key[], selectedRows: UnmatchedAssetInterface[]) => {
            if (selectedRows.length > 0 && selectedRows[0]) {
                onConflictSelect(selectedRows[0]);
            }
        },
        getCheckboxProps: getCheckboxProps,
    };

    const handleRemoveMarkAsPromo = (record: UnmatchedAssetInterface) => {
        if (onRemoveRecord) {
            onRemoveRecord(record);
        }
    };

    const handleOpenAlternateTitleForm = (record: UnmatchedAssetInterface) => {
        alternateTitles.openForm(record);
    };

    const handleSaveAlternateTitle = async () => {
        try {
            // Call original save method which returns the record
            const record = await alternateTitles.saveAlternateTitle();
            
            // Mark record as processing
            if (record && onAlternateTitleCreated) {
                onAlternateTitleCreated(record);
            }
        } catch (error) {
            // Error already handled in the hook, no need to do anything here
            console.error("Failed to save alternate title:", error);
        }
    };

    // Make sure titleConflictData is an array before mapping
    const transformedData = Array.isArray(titleConflictData) 
        ? titleConflictData
            .filter(item => item && typeof item === 'object') // Filter out null/undefined items
            .map(item => ({
                ...item,
                key: item.id || Math.random().toString(), // Fallback key for safety
                ID: item.id || '',
                REF_ID: item.ref_id || '',
                TITLE: item.title || '',
                LINKED_METADATA: (item.ref_id && linkedMetadata[item.ref_id]) 
                    ? linkedMetadata[item.ref_id].ref_id
                    : 'Not Linked',
                STATUS: (item.id && processingRecords[item.id]) ? 'Processing' : 'Ready'    
            }))
        : [];

    const actionsColumn = {
        title: 'Actions',
        key: 'actions',
        width: 80,
        render: (_: any, record: UnmatchedAssetInterface) => {
            if (!record || !record.id) {
                return null; // Don't render actions for invalid records
            }
            
            // Ensure isProcessing is always a boolean (using double-bang operator)
            const isProcessing = !!(record.id && processingRecords[record.id] === true);
            
            if (isProcessing) {
                return (
                    <Tooltip title="Processing...">
                        <SyncOutlined spin style={{ color: '#1890ff' }} />
                    </Tooltip>
                );
            }
            
            return (
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: '1',
                                label: 'Create Alternate Title',
                                onClick: () => handleOpenAlternateTitleForm(record)
                            },
                            {
                                key: '2',
                                label: 'Remove / Mark as Promo',
                                onClick: () => handleRemoveMarkAsPromo(record)
                            }
                        ]
                    }}
                    trigger={['click']}
                    disabled={isProcessing}
                >
                    <Button type="text" icon={<SettingOutlined />} disabled={isProcessing} />
                </Dropdown>
            );
        }
    };

    const updatedColumns = [...titleConflictColumns.map(column => {
        if (column.dataIndex === 'TITLE' || column.key === 'TITLE') {
            return { ...column, width: 170 };
        }
        if (column.dataIndex === 'REF_ID' || column.key === 'ref_id') {
            return { ...column, width: 90 };
        }
        return column;
    }), 
    {
        title: 'Linked Metadata',
        dataIndex: 'LINKED_METADATA',
        key: 'LINKED_METADATA',
        width: 90,
    },
    {
        title: 'Status',
        dataIndex: 'STATUS',
        key: 'STATUS',
        width: 75,
        render: (status: string) => (
            <span style={{ 
                color: status === 'Processing' ? '#1890ff' : 'inherit',
                fontWeight: status === 'Processing' ? 'bold' : 'normal'
            }}>
                {status}
            </span>
        )
    },
    actionsColumn];

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrentPage(pagination.current || 1);
        setPageSize(pagination.pageSize || 10);
    };

    const paginatedData = transformedData.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    return (
        <StyledDiv>
            <Row justify="space-between" align="middle">
                <Col>
                    <ComponentTitle>Unmatched Assets</ComponentTitle>
                </Col>
            </Row>
            <Table<UnmatchedAssetInterface>
                rowSelection={rowSelection}
                columns={updatedColumns}
                dataSource={paginatedData}
                scroll={{ y: height - 200 }}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: titleConflictData.length,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                loading={isLoading}
                rowKey={(record) => record?.id || Math.random().toString()} // Fallback key for safety
                onChange={handleTableChange}
                rowClassName={(record) => processingRecords[record?.id] ? 'processing-row' : ''}
                locale={{
                    emptyText: isLoading ? 'Loading...' : 'No unmatched assets found. All assets are matched!'
                }}
            />
            
            <AlternateTitleForm
                isOpen={alternateTitles.isFormOpen}
                saveEditData={handleSaveAlternateTitle}
                form={alternateTitles.form}
                isLoading={alternateTitles.isLoading}
                handleCancel={alternateTitles.handleCancel}
                editId={alternateTitles.editId}
            />
        </StyledDiv>
    );
};

export default UnmatchedAssets;