import React, { Fragment, useEffect, useState } from "react";
import { Form, message } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { EditOutlined } from "@ant-design/icons";
import { useLocation, useOutletContext } from "react-router-dom";

import {
	HomeTabKeyEnum,
	SETTINGS_DATA,
	getMetaData,
	searchHandler,
	updateUserPageSizeSettings,
} from "../../utils";
import { CustomBtn } from "../../series/StyledComponent";
import { PaginationProps } from "antd/lib/pagination";
import { SortObj } from "../../videos/videos";
import { authAxios } from "../../utils/session_utils";
import { initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import { TextWithCopyButton } from "../../cue-points/hooks/cuePointColumns";
import { orderObj } from "../../videos/hooks/useVideosHooks";
import { refIdColumn } from "../../utils/table-columns";
import { DeletePopConfirm } from "../../custom-components";

export interface MetaRecord {
	id: string;
	language: string;
	ref_id: string;
	title: string;
	clean_title: string;
	keywords: string;
	description_100: string;
	description_250: string;
	description_full: string;
}

export interface MetadataFilter {
	ref_id?: string;
	searchQuery?: string;
}

export const useMetadataHook = () => {
	const location = useLocation();
	const { user, setUser }: any = useOutletContext();
	const [metaData, setMetaData] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [form] = Form.useForm();
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [isAddMetaFormOpen, setIsMetaFormOpen] = useState<boolean>(false);
	const [editId, setEditId] = useState<string | null>(null);
	const [metaFilterModalOpen, setMetaFilterModalOpen] = useState<boolean>(false);
	const [isFilteredMetadata, setIsFilteredMetadata] = useState<boolean>(false);
	const [metadataSortObj, setMetadataSortObj] = useState<SortObj>({
		column: "ref_id",
		order: "asc",
	});
	const [metadataFilterObj, setMetadataFilterObj] = useState<MetadataFilter | undefined>(undefined);
	const [pagination, setPagination] = useState<PaginationProps>({
		...initialPaginationObj,
		pageSize: user.page_sizes?.metadata ?? 10,
	});

	const addDataFields = [
		"language",
		"ref_id",
		"title",
		"keywords",
		"description_100",
		"description_250",
		"description_full",
	];

	useEffect(() => {
		if (location.pathname === HomeTabKeyEnum.METADATA) {
			setIsLoading(true);
			getMetadataHandler();
		}
	}, [
		location.pathname,
		pagination.current,
		pagination.pageSize,
		metadataSortObj,
		metadataFilterObj,
		searchQuery,
	]);

	const getMetadataHandler = () => {
		getMetaData(setMetaData, setIsLoading, pagination, setPagination, metadataSortObj, {
			...metadataFilterObj,
			searchQuery,
		});
	};
	const handleTableChange = (pagination: PaginationProps, _: any, sorter: any, { action }: any) => {
		switch (action) {
			case "paginate": {
				if (pagination.pageSize !== user.page_sizes.metadata) {
					const updatedSettingsData: SETTINGS_DATA = {
						user_id: user.user_id,
						page_sizes: { ...user.page_sizes, metadata: pagination.pageSize },
					};
					updateUserPageSizeSettings(updatedSettingsData);
					setUser({ ...user, ...updatedSettingsData });
				}
				setPagination(pagination);
				break;
			}
			case "sort": {
				if (!sorter.order) return;
				setMetadataSortObj({ column: sorter.field, order: orderObj[sorter.order] });
				break;
			}
		}
	};

	const saveMetaData = async () => {
		try {
			const data = await form.validateFields();
			setIsLoading(true);

			const updatedata = {
				metaData: {
					...data,
					id: editId,
				},
			};

			const config = {
				method: !editId ? "POST" : "PUT",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/metadata`,
				headers: {
					"Content-Type": "application/json",
				},
				data: updatedata,
			};
			const response = await authAxios(config);
			if (response.data) {
				getMetadataHandler();
				message.success("Metadata Updated Successfully");
				form.resetFields(addDataFields);
				setIsMetaFormOpen(false);
			}
		} catch (err) {
			console.error(err);
			setIsLoading(false);
		}
	};

	const deleteMetadata = (metaId: string) => {
		try {
			setIsLoading(true);
			const config = {
				method: "delete",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/metadata/${metaId}`,
				headers: {},
			};

			authAxios(config)
				.then(() => {
					getMetadataHandler();
					message.success("Metadata deleted Successfully");
				})
				.catch(error => {
					console.error(error);
				});
		} catch (err) {
			setIsLoading(false);
			console.error("erro", err);
		}
	};
	const handleCancel = () => {
		form.resetFields(addDataFields);
		setIsMetaFormOpen(false);
		setEditId(null);
	};

	const editHelper = (record: MetaRecord) => {
		setEditId(record.id);
		form.setFieldsValue({ ...record });
		setIsMetaFormOpen(true);
	};

	const getFilteredMetadata = async () => {
		try {
			const ref_id = await form.getFieldValue("ref_id_filter");
			setMetaFilterModalOpen(false);
			if (ref_id?.length) {
				setMetadataFilterObj({ ref_id });
				setIsFilteredMetadata(true);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const metaFiterModalCancelHandler = () => {
		form.resetFields();
		setMetaFilterModalOpen(false);
	};

	const clearFilter = () => {
		setIsFilteredMetadata(false);
		setMetadataFilterObj(undefined);
		form.resetFields();
	};

	const searchData = async () => {
		try {
			setPagination({ ...initialPaginationObj, pageSize: pagination.pageSize });
			searchHandler(form, timeoutId, setTimeoutId, setSearchQuery);
		} catch (err) {
			console.error({ err });
		}
	};

	const columnSeries: ColumnsType<object> = [
		{
			title: "Language",
			dataIndex: "language",
			key: "language",
			width: "100px",
		},
		{
			...refIdColumn,
			width: "120px",
			defaultSortOrder: "ascend",
			sorter: (a: any, b: any) => a.ref_id.localeCompare(b.ref_id),
		},
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			width: "180px",
			render: (value: string) => <TextWithCopyButton value={value} />,
		},
		{
			title: "Clean Title",
			dataIndex: "clean_title",
			key: "clean_title",
			width: "160px",
			render: (value: string) => <TextWithCopyButton value={value} />,
		},
		{
			title: "Keywords",
			dataIndex: "keywords",
			key: "keywords",
			width: "160px",
		},
		{
			title: "Description 100",
			dataIndex: "description_100",
			key: "description_100",
			width: "220px",
			render: (value: string) => <TextWithCopyButton value={value} />,
		},
		{
			title: "Description 250",
			dataIndex: "description_250",
			key: "description_250",
			width: "300px",
			render: (value: string) => <TextWithCopyButton value={value} />,
		},
		{
			title: "Full Description",
			dataIndex: "description_full",
			key: "description_full",
			width: "560px",
			render: (value: string) => <TextWithCopyButton value={value} />,
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			align: "right",
			width: "80px",
			render: (value, record: any) => {
				return (
					<Fragment>
						<CustomBtn
							icon={<EditOutlined />}
							type={"link"}
							onClick={e => {
								e.stopPropagation();
								editHelper(record);
							}}
						/>
						<DeletePopConfirm
							title="Delete Metadata?"
							deleteHandler={() => deleteMetadata(record.id)}
						>
							Are you sure you want to delete this metadata?
						</DeletePopConfirm>
					</Fragment>
				);
			},
		},
	];
	return {
		columnSeries,
		metaData,
		isLoading,
		setIsLoading,
		saveMetaData,
		isAddMetaFormOpen,
		setIsMetaFormOpen,
		form,
		handleCancel,
		editId,
		handleTableChange,
		getFilteredMetadata,
		metaFiterModalCancelHandler,
		metaFilterModalOpen,
		setMetaFilterModalOpen,
		isFilteredMetadata,
		setIsFilteredMetadata,
		clearFilter,
		editHelper,
		pagination,
		searchData,
	};
};
