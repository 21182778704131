import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { CloseCircleOutlined, DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import {
	Button,
	ButtonProps,
	Checkbox,
	Col,
	Form,
	FormInstance,
	Input,
	Modal,
	ModalProps,
	Popover,
	Row,
	Spin,
	Tag,
	Typography,
} from "antd";
import { NotesButton } from "../episode/hooks/useEpisodeHook";
import { CustomBtn } from "../series/StyledComponent";

export const StledSpin = styled(Spin)`
	& .ant-spin-container {
		height: 100%;
	}
`;

export const ComponentTitle = styled(Typography.Title)`
	color: #47cb38 !important;
	margin-bottom: 0.2em !important;
`;

export const ExportButton: FC<ButtonProps> = ({ ...otherProps }) => (
	<Button {...otherProps} htmlType="submit">
		Export
	</Button>
);

type ExportModalProps = {
	isExportModalOpen: boolean;
	exportCSV: () => void;
	setIsExportModalOpen: (value: boolean) => void;
	form: FormInstance;
};

export const ExportModal: FC<ExportModalProps> = ({
	isExportModalOpen,
	exportCSV,
	setIsExportModalOpen,
	form,
}) => {
	return (
		<Modal
			open={isExportModalOpen}
			footer={
				<div style={{ width: "100%", textAlign: "center" }}>
					<Button
						type="primary"
						onClick={() => {
							setIsExportModalOpen(false);
							exportCSV();
						}}
					>
						Export CSV
					</Button>
				</div>
			}
			title="Export CSV"
			onCancel={() => setIsExportModalOpen(false)}
		>
			<Typography.Text>This will export the data according to selected filters.</Typography.Text>
			<Form form={form} initialValues={{ include_distribution_platforms: false }}>
				<Form.Item name="include_distribution_platforms">
					<Checkbox.Group>
						<Checkbox key="include_distribution_platforms" value="include_distribution_platforms">
							Include Distribution Platforms
						</Checkbox>
					</Checkbox.Group>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export const PageTitleLastCol = styled(Col)`
	display: flex;
	align-items: center;
`;

const StyledSearchForm = styled(Form)`
	display: flex;
	align-items: center;
`;

const StyledSearchFormItem = styled(Form.Item)`
	&.ant-form-item {
		margin-bottom: 0 !important;
		width: 240px;
		margin-left: 4px;
	}
	& .ant-input-search-button {
		color: #61f061 !important;
	}
`;

interface SearchForm {
	form: FormInstance;
	searchData: () => void;
	placeholder: string;
}

export const SearchForm: React.FC<SearchForm> = ({ form, searchData, placeholder }) => {
	return (
		<StyledSearchForm form={form}>
			<StyledSearchFormItem name="search">
				<Input placeholder={placeholder} onChange={searchData} suffix={<SearchOutlined />} />
			</StyledSearchFormItem>
		</StyledSearchForm>
	);
};

export const CloseIcon = () => {
	return <CloseCircleOutlined style={{ fontSize: "16px" }} title="Clear" />;
};

export const StyledLink = styled(Link)`
	color: #47cb38 !important;
	text-decoration-line: underline;
	cursor: pointer;
`;

export const CreateNewListCol = styled(Col)`
	display: flex;
	cursor: pointer;
`;

export const VideoSearchItem = styled(Input)`
	margin-bottom: 16px;
	border-width: 0;
	border-bottom-width: 3px;
	border-radius: 0;
	border-color: #47cb38 !important;

	&:focus {
		box-shadow: none;
	}
	&:focus-within {
		box-shadow: none;
	}

	&::placeholder {
		color: rgba(0, 0, 0, 0.5);
		font-weight: bold;
	}
`;

export const BulkAddDataModal = styled(Modal)`
	& .ant-modal-body {
		height: calc(100vh - 120px);
		overflow-y: auto;
		overflow-x: hidden;
	}
`;

export const SelectedCountTag = styled(Tag)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 8px;
	height: 30px;
	font-size: 14px;
	font-weight: 600;
	& span {
		height: 30px;
		line-height: 30px;
	}
	& #close-icon {
		border-left: 1px solid #47cb38;
		padding-left: 8px;
		margin-left: 8px;
		cursor: pointer;
	}
`;

export const DescriptionText: FC<{ text: string }> = ({ text }) => {
	return text?.length ? (
		<Popover trigger={["click", "hover"]} title={text}>
			<NotesButton onClick={e => e.stopPropagation()}>
				<CgNotes />
			</NotesButton>
		</Popover>
	) : null;
};

export const BlueFilterInfoTitle: FC<{ title: string }> = ({ title }) => {
	return (
		<Typography.Title level={5} style={{ color: "#1e80c7" }}>
			{title}
		</Typography.Title>
	);
};

export const FilterInfoTitle: FC<{ children: any }> = ({ children }) => {
	return <h4 style={{ fontSize: "15px" }}>{children}</h4>;
};

export const BorderedRow = styled(Row)`
	border: 1px solid #d9d9d9;
	padding: 8px;
	border-radius: 8px;
	margin-bottom: 16px;
`;

export const DeletePopConfirm: React.FC<ModalProps & { deleteHandler: () => void, loading?: boolean }> = ({
	title,
	children,
	cancelText,
	okText,
	deleteHandler,
	loading,
}) => {
	const [showModal, setShowModal] = useState(false);
	return (
		<>
			<CustomBtn
				onClick={e => {
					e.stopPropagation();
					setShowModal(true);
				}}
				type={"link"}
				loading={loading}
				icon={<DeleteOutlined title="Delete" style={{ color: "#D10000" }} />}
			/>
			<Modal
				closeIcon={null}
				centered
				open={showModal}
				onOk={e => {
					e.stopPropagation();
					setShowModal(false);
					deleteHandler();
				}}
				cancelText={cancelText ?? "No"}
				onCancel={e => {
					e.stopPropagation();
					setShowModal(false);
				}}
				okButtonProps={{ danger: true }}
				okText={okText ?? "Yes"}
				title={title ?? "Delete Data?"}
			>
				{children ?? "Do you want to delete this record?"}
			</Modal>
		</>
	);
};
