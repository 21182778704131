import { makeKeyInLowerCaseHelper } from "../../utils";
import { authAxios } from "../../utils/session_utils";

export const getEpisodeRelatedVideos = async (ref_id: string) => {
    try {
        const response = await authAxios({
            method: "GET",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/video/?filter=${JSON.stringify({
                ref_id,
                showHiddenEpisodes: true,
            })}`,
        });
        const convertedObject = await makeKeyInLowerCaseHelper(response.data.data);
        return convertedObject;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const getEpisodeRelatedCuePoints = async (episodeId: string) => {
    try {
        const response = await authAxios({
            method: "GET",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/cuepoints/${episodeId}`,
        });
        const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
        return convertedObject;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const getEpisodeRelatedThumbnails = async (episodeId: string) => {
    try {
        const response = await authAxios({
            method: "GET",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/thumbnails/${episodeId}`,
        });
        const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
        return convertedObject;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const getEpisodeRelatedCaptions = async (episode_id: string) => {
    try {
        const response = await authAxios({
            method: "GET",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/captions?filter=${JSON.stringify({
                episode_id,
            })}`,
        });
        const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
        return convertedObject;
    } catch (error) {
        console.error(error);
        return [];
    }
};
