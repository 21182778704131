import React, { Fragment } from "react";
import { lastModifiedCol, refIdColumn } from "../../utils/table-columns";
import { CustomBtn } from "../../series/StyledComponent";
import { Link } from "react-router-dom";
import { DeletePopConfirm } from "../../custom-components";
import { EditOutlined } from "@ant-design/icons";

const getColumns = (
    deletePromo: (id: string) => void,
	loading: boolean
) => [
    {
        ...refIdColumn,
        width: "120px",
    },
    lastModifiedCol(),
    {
        title: "URL",
        dataIndex: "promo_url",
        key: "promo_url",
    },
    {
        title: "Duration",
        dataIndex: "duration",
        key: "duration",
    },
    {
        title: "Amagi ID",
        dataIndex: "amagi_id",
        key: "amagi_id",
    },
    {
        title: "Action",
        dataIndex: "promo_id",
        key: "promo_id",
        // align: "right",
        render: (value: string) => {
            return (
                <Fragment>
                    <Link to={`/promo/${value}`}>
                        <CustomBtn
                            onClick={e => {
                                if (e.metaKey || e.ctrlKey) {
                                    e.stopPropagation();
                                }
                                // sessionStorage.setItem("episodeFilter", JSON.stringify(episodeFilterObj));
                            }}
                            icon={<EditOutlined />}
                            type={"link"}
                        />
                    </Link>
                    <DeletePopConfirm
                        loading={loading}
                        title="Delete Promo?"
                        deleteHandler={() => deletePromo(value)}
                    >
                        Are you sure you want to delete this promo? 
                    </DeletePopConfirm>
                </Fragment>
            );
        },
    },
]

export default getColumns;