import { useState, useEffect, useMemo } from "react";
import { Form, PaginationProps, notification } from "antd";
import { useOutletContext } from "react-router-dom";
import { UnmatchedAssetInterface } from "../interfaces";
import { initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import { SortObj } from "../../videos/videos";
import { authAxios, getEmailFromSessionStorage } from "../../utils/session_utils";

const useTitleConflicts = () => {
    const [form] = Form.useForm();
    const { user }: any = useOutletContext();
    const [pagination, setPagination] = useState<PaginationProps>({
        ...initialPaginationObj,
        pageSize: user?.page_sizes?.title_conflicts || 10,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [titleConflictData, setTitleConflictData] = useState<UnmatchedAssetInterface[]>([]);
    const [titleConflictEditId, setTitleConflictEditId] = useState<string | undefined>();
    const [isTitleConflictFormOpen, setIsTitleConflictFormOpen] = useState(false);
    const [titleSortObj, setTitleSortObj] = useState<SortObj>({ column: "ref_id", order: "asc" });
    const [availableMetadata, setAvailableMetadata] = useState<any[]>([]);
    
    
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const [recordToRemove, setRecordToRemove] = useState<UnmatchedAssetInterface | null>(null);

    const fetchTitleConflicts = () => {
        setIsLoading(true);
        // Initialize with empty array to ensure table always renders
        setTitleConflictData([]);
        
        authAxios({
            method: "GET",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/title-conflict`,
        })
            .then((response: any) => {                
                try {
                    // Check if data exists and is an array before mapping
                    if (response.data && response.data.data && Array.isArray(response.data.data)) {
                        // Transform the data to use lowercase property names
                        const transformedData = response.data.data.map((item: any) => ({
                            id: item.ID,
                            ref_id: item.REF_ID,
                            title: item.TITLE,
                            notes: item.NOTES
                        }));
                        
                        setTitleConflictData(transformedData);
                    } else {
                        // Just maintain the empty array initialized above
                        console.log('No title conflicts found or invalid data format');
                    }
                    
                    // Set pagination total (default to 0 if not available)
                    setPagination(prev => ({ 
                        ...prev, 
                        total: response.data && response.data.total ? response.data.total : 0
                    }));
                } catch (parseError) {
                    console.error('Error processing title conflicts data:', parseError);
                    // Don't show error to user, just log it
                }
            })
            .catch((err: any) => {
                console.error('Error fetching title conflicts:', err);
                // Silently log the error without showing notification to the user
                console.log('API error occurred, but continuing with empty data');
                // Empty array is already set above
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    
    useEffect(() => {
        fetchTitleConflicts();
    }, []);

    const deleteTitleConflict = (record: UnmatchedAssetInterface) => {
        setIsLoading(true);
        // Get the email from session storage
        const userEmail = getEmailFromSessionStorage();
        
        authAxios({
            method: "DELETE",
            url: `${process.env.REACT_APP_BACK_END_API}/snowflake/title-conflict`,
            data: {record, userEmail}
        })
            .then((response) => {
                notification.success({
                    message: 'Success',
                    description: 'Viewership record deleted successfully',
                });
                // Refresh the list after deletion
                fetchTitleConflicts();
            })
            .catch((err) => {
                console.error('Error deleting title conflict:', err);
                notification.error({
                    message: 'Error',
                    description: 'Failed to delete viewership record',
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    // Add these functions for the remove modal
    const showRemoveModal = (record: UnmatchedAssetInterface) => {
        setRecordToRemove(record);
        setIsRemoveModalOpen(true);
    };

    const hideRemoveModal = () => {
        setIsRemoveModalOpen(false);
        setRecordToRemove(null);
    };

    const handleDeleteConfirm = async () => {
        if (recordToRemove) {
            await deleteTitleConflict(recordToRemove);
            hideRemoveModal();
        }
    };

    const handleTableChange = (
        newPagination: PaginationProps,
        _: any,
        sorter: any
    ) => {
        setPagination(prev => ({ ...prev, current: newPagination.current, pageSize: newPagination.pageSize }));
        if (sorter.columnKey) {
            setTitleSortObj({
                column: sorter.columnKey,
                order: sorter.order === "ascend" ? "asc" : "desc",
            });
        }
    };

    const titleConflictColumns = useMemo(() => [
        {
            title: 'Ref ID',
            dataIndex: 'REF_ID',
            key: 'ref_id',
            sorter: true,
        },
        {
            title: 'Title',
            dataIndex: 'TITLE',
            key: 'title',
            sorter: true,
        }
    ], []);

    // Remove a record from the display (without calling the API)
    const removeRecordFromDisplay = (recordId: string) => {
        setTitleConflictData(prev => prev.filter(record => record.id !== recordId));
    };

    return {
        isLoading,
        titleConflictData,
        titleConflictColumns,
        isTitleConflictFormOpen,
        handleTableChange,
        setIsTitleConflictFormOpen,
        form,
        titleConflictEditId,
        pagination,
        availableMetadata,
        setPagination,
        fetchTitleConflicts,
        deleteTitleConflict,
        isRemoveModalOpen,
        recordToRemove,
        showRemoveModal,
        hideRemoveModal,
        handleDeleteConfirm,
        removeRecordFromDisplay,
    };
};

export default useTitleConflicts;