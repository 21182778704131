import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { JSX } from 'react/jsx-runtime';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getSeriesSelectData, submitScheduleData } from '../../utils';

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: 50px repeat(7, 1fr);
  grid-template-rows: 50px repeat(24, 1fr);
  gap: 1px;
  background-color: #ccc;
`;

const Cell = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
  cursor: pointer;
`;

const HeaderCell = styled(Cell)`
  background-color: #f0f0f0;
  font-weight: bold;
`;

const HourCell = styled(Cell)`
  background-color: #e0e0e0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px; /* Add horizontal spacing between items */
`;

const NavButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  margin-left: 10px;
`;

const CenterButton = styled(NavButton)`
  margin-left: auto;
  margin-right: auto;
`;


const HighlightedCell = styled(Cell)`
  background-color: #ffeb3b;
`;

const Dropdown = styled.select`
  display: inline-block;
  width: auto;
  height: auto;
  background-color: white;
  border: 1px solid #ccc;
  font-size: 14px;
  margin-top: 5px;
`;

type SeriesDropdownValue = {
  key: string;
  id: string;
  titles: string;
};

const SchedulerComponent = () => {
  const defaultCellValue = 'Click to add';
  const [selectedCell, setSelectedCell] = useState<{ dateString: string } | null>(null);

  const [seriesDropdownValues, setSeriesDropdownValues] = useState<SeriesDropdownValue[]>([]);
  const [seriesMap, setSeriesMap] = useState<{ [key: string]: string }>({});
  const [isSeriesValuesLoaded, setIsSeriesValuesLoaded] = useState<boolean>(false);
  const [cellValues, setCellValues] = useState<{ [key: string]: string }>({});
  const [isCascadeChangeColumnEnabled, setIsCascadeChangeColumnEnabled] = useState<boolean>(true);
  const [isCascadeChangeDayOfWeekEnabled, setIsCascadeChangeDayOfWeekEnabled] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Date | null>(moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState<Date | null>(moment().endOf('month').toDate());
  const [currentStartDate, setCurrentStartDate] = useState<Date | null>(startDate);
  const [areAllCellsInRangeFilled, setAreAllCellsInRangeFilled] = useState<boolean>(false);


  useEffect(() => {
    if (!isSeriesValuesLoaded) {
      getSeriesSelectData(setSeriesDropdownValues, setSeriesMap, setIsSeriesValuesLoaded); 
    }
  }, [isSeriesValuesLoaded]);

  useEffect(() => {
    setCurrentStartDate(startDate);
    cleanupCellValuesOutsideRange(startDate, endDate);
  }, [startDate]);

  useEffect(() => {
    cleanupCellValuesOutsideRange(startDate, endDate);
  }, [endDate]);

  useEffect(() => {
    // Check if all cells in range are filled
    let allCellsInRangeFilled = true;
    if(startDate && endDate) {
      let dt = moment(startDate).toDate();
      const endDt = moment(endDate).toDate();
      while(dt <= endDt) {
        const dtStr = moment(dt).format('YYYY-MM-DD');
        for (let h = 0; h < 24; h++) {
          const key = generateCellKey(dtStr, h);
          if (!cellValues[key]) {
            allCellsInRangeFilled = false;
            break;
          }
        }
        dt = moment(dt).add(1, 'days').toDate();
      }
      setAreAllCellsInRangeFilled(allCellsInRangeFilled);
    }
  }, [startDate, endDate, cellValues]);

  const cleanupCellValuesOutsideRange = (start: Date | null, end: Date | null) => {
    if(start && end) {
      const newCellValues: { [key: string]: string } = {};
      Object.keys(cellValues).forEach((key) => {
        const parsedKey = parseCellKey(key);
        const cellDate = moment(parsedKey.dateStr, 'YYYY-MM-DD').toDate();
        if (cellDate >= start && cellDate <= end) {
          newCellValues[key] = cellValues[key];
        }
      });
      setCellValues(newCellValues);
    }
  }

  const handleCellClick = (date: string, hour: number) => {
    setSelectedCell({ dateString: `${date} ${hour}` });
  };

  const parseCellKey = (key: string) => {
    const [dateStr, hourStr] = key.split('_');
    return { dateStr: dateStr, hour: parseInt(hourStr, 10) };
  }

  const generateCellKey = (day: string, hour: number) => {
    return `${day}_${hour}`;
  }

  const handleDropdownChange = (day: string, hour: number, value: string) => {
    const key = generateCellKey(day, hour);
    setCellValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));

    // Update all cells below the selected cell in the same column
    if(isCascadeChangeColumnEnabled) {
      let renderOrder = [[(hour + 1), 24], [0, 6]];
      if(hour >= 0 && hour < 6) {
        renderOrder = [[(hour + 1), 6]];
      }
      renderOrder.forEach(([start, end]) => {
        for (let h = start; h < end; h++) {
          const newKey = generateCellKey(day, h);
          setCellValues((prevValues) => ({
            ...prevValues,
            [newKey]: value,
          }));
        }
      });
    }

    // From startDate to endDate Update all cells of same day of week and cascade change day of week is enabled
    if(isCascadeChangeDayOfWeekEnabled) {
      const dayOfWeekDate = moment(day).toDate();
      // jump to next dayOfWeek
      let nextDayOfWeekDate = moment(dayOfWeekDate).add(7, 'days').toDate();
      while(nextDayOfWeekDate <= moment(endDate).toDate()) {
        const nextDayOfWeek = moment(nextDayOfWeekDate).format('YYYY-MM-DD');
        const renderOrder = [[6, 24], [0, 6]];
        renderOrder.forEach(([start, end]) => {
          for (let h = start; h < end; h++) {
            const newKey = generateCellKey(nextDayOfWeek, h);
            setCellValues((prevValues) => ({
              ...prevValues,
              [newKey]: value,
            }));
          }
        });
       nextDayOfWeekDate = moment(nextDayOfWeekDate).add(7, 'days').toDate();
      }
    }
    setSelectedCell(null);
  };


  const renderHeader = () => {
    const days = [<HeaderCell key="hour-header"></HeaderCell>];
    for (let i = 0; i < 7; i++) {
      const day = moment(currentStartDate).clone().add(i, 'days');
      days.push(
        <HeaderCell key={i}>
          {day.format('dddd')}<br />{day.format('YYYY-MM-DD')}
        </HeaderCell>
      );
    }
    return days;
  };


  const renderCells = () => {
    const cells: JSX.Element[] = [];
    const renderOrder = [[6, 24], [0, 6]];
    const today = moment().format('YYYY-MM-DD');

    renderOrder.forEach(([start, end]) => {
      for (let hour = start; hour < end; hour++) {
        cells.push(<HourCell key={`hour-${hour}`}>{moment({ hour }).format('hh:mm A')}</HourCell>);
        for (let day = 0; day < 7; day++) {
          const currentDay = moment(currentStartDate).clone().add(day, 'days').format('YYYY-MM-DD');
          const isToday = currentDay === today;
          const isSelected = selectedCell && selectedCell.dateString === `${currentDay} ${hour}`;
          const key = generateCellKey(currentDay, hour);
          const cellValue = cellValues[key];
          const cellDisplayValue = seriesMap[cellValue];


          cells.push(
            <Cell
            as={isToday ? HighlightedCell : 'div'}
            key={`${key}`}
            onClick={() => handleCellClick(currentDay, hour)}
          >
            {isSelected ? '' : cellDisplayValue || (<span style={{ color: 'gray' }}>{defaultCellValue}</span>)}
            {isSelected && (
              <Dropdown value={cellValue || ''}
              onChange={(e) => handleDropdownChange(currentDay, hour, e.target.value)} >
              <option value="" disabled>Select a value</option>
               {seriesDropdownValues.map((value) => (
                 <option key={value.key} value={value.id}>
                    {value.titles}
                  </option>
                ))}
              </Dropdown>
            )}
          </Cell>
          );
        }
      }
    });
    return cells;
  };

  const moveOneDayBack = () => {
    setCurrentStartDate(moment(currentStartDate).subtract(1, 'days').toDate());
  };

  const moveOneDayForward = () => {
    setCurrentStartDate(moment(currentStartDate).add(1, 'days').toDate());
  };

  const handleSubmit = () => {
    console.log(cellValues);
    console.log(startDate, endDate);
    if(startDate && endDate) {
      const data = {
        cellValues,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD')
      }
      submitScheduleData(data, () => {
        alert('Data submitted successfully');
      }, (error) => {
        alert('Error submitting data');
      });
    } else {
      alert('Please select start and end date');
    }
  }

  return (
    <div style={{ backgroundColor: 'white', padding: '10px' }}>
      <h1 style={{ textAlign: 'center' }}>Weekly Calendar</h1>
      <ButtonContainer>
        {currentStartDate && startDate && moment(currentStartDate).isAfter(startDate) && (
          <NavButton onClick={moveOneDayBack}>Previous</NavButton>
        )}
        <label>
          <input
            type="checkbox"
            checked={isCascadeChangeColumnEnabled}
            onChange={() => setIsCascadeChangeColumnEnabled(!isCascadeChangeColumnEnabled)}
          />
          Cascade Change Column
        </label>
        <label>
          <input
            type="checkbox"
            checked={isCascadeChangeDayOfWeekEnabled}
            onChange={() => setIsCascadeChangeDayOfWeekEnabled(!isCascadeChangeDayOfWeekEnabled)}
          />
          Cascade Change Day of Week
        </label>
        <div>
          <label>Start Date: </label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div>
          <label>End Date: </label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        {areAllCellsInRangeFilled && <CenterButton onClick={handleSubmit}>SUBMIT</CenterButton>}
        {currentStartDate && endDate && moment(currentStartDate).add(7, 'days').isBefore(endDate) && (
          <NavButton onClick={moveOneDayForward}>Next</NavButton>
        )}
      </ButtonContainer>
      <CalendarGrid>
        {renderHeader()}
        {renderCells()}
      </CalendarGrid>
    </div>
  );
};

export default SchedulerComponent;