import React from "react";
import { Tag } from "antd";
import { CustomBtn } from "../../series/StyledComponent";
import { CopyFilled, CopyOutlined, EditOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { copyString } from "../../utils";
import { refIdColumn } from "../../utils/table-columns";
import { DeletePopConfirm } from "../../custom-components";

export const TextWithCopyButton: React.FC<{ value: any }> = ({ value }) => {
	return value?.length ? (
		<span>
			<CustomBtn
				style={{ cursor: "copy" }}
				title="Copy Cue Points"
				icon={<CopyOutlined />}
				type={"link"}
				onClick={e => {
					e.stopPropagation();
					copyString(value);
				}}
			/>
			&nbsp;
			{value}
		</span>
	) : (
		<></>
	);
};

const cuePointsColumn = (
	editHelper: (record: any, isDuplicate?: boolean) => void,
	deleteCuePoint: (id: string) => void
) => {
	const columnCuePoints: ColumnsType<any> = [
		{
			...refIdColumn,
			width: "120px",
		},
		{
			title: "Cue Points",
			dataIndex: "cue_points",
			key: "cue_points",
			render: (value: string) => {
				return <TextWithCopyButton value={value} />;
			},
		},
		{
			title: "Offset Time",
			dataIndex: "offset_time",
			key: "offset_time",
		},
		{
			title: "Distribution Platforms",
			dataIndex: "cuepoint_distribution_names",
			key: "cuepoint_distribution_names",
			width: "220px",
			render: (value: any) => {
				return value
					? value?.split(",").map((title: string) => (
							<Tag key={title} title={title}>
								{title}
							</Tag>
					  ))
					: "";
			},
		},
		{
			title: "Actions",
			dataIndex: "action",
			key: "action",
			render: (value, record: any) => {
				return (
					<>
						<CustomBtn
							title="Edit"
							icon={<EditOutlined />}
							type={"link"}
							onClick={e => {
								e.stopPropagation();
								editHelper(record);
							}}
						/>
						<CustomBtn
							title="Clone"
							icon={<CopyFilled />}
							type={"link"}
							onClick={e => {
								e.stopPropagation();
								editHelper(record, true);
							}}
						/>
						<DeletePopConfirm
							title="Delete Cue Point?"
							deleteHandler={() => deleteCuePoint(record.id)}
						>
							Are you sure you want to delete this Cue Point?
						</DeletePopConfirm>
					</>
				);
			},
		},
	];
	return columnCuePoints;
};

export default cuePointsColumn;
