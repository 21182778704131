import React from "react";
import { ComponentTitle, StledSpin } from "../../custom-components";
import { Col, Row, Upload, Button, Card, Form, Input } from "antd";
import styled from "@emotion/styled";
import useEPGInputsUploadHook from "./hooks/useEPGInputsUploadHook";
import { TaskRow } from "../../zype/styled-components";
import { InboxOutlined } from "@ant-design/icons";
import { Navigate } from "react-router-dom";



const { Dragger } = Upload;

export const StyledDragger = styled(Dragger)`
	padding: 10px;
	& .ant-upload-drag {
		padding: 10px;
	}
`;
type CreateEPGPramsFieldType = {
	uploadUUID: string;
	channelName: string;
  };

  
const EPGInputsUploadComponent = () => {
	// const { height } = useScreenSizeHook();
	const { loading, handleSubmit, uploadProps, form, redirectToBatchJobs } = useEPGInputsUploadHook();
	if (redirectToBatchJobs) {
		return <Navigate to="/batch-jobs" />;
	}

	return (
		<StledSpin spinning={loading}>
			<Row justify="center">
				<Col>
					<ComponentTitle>Create EPG from Amagi Schedules</ComponentTitle>
				</Col>
			</Row>
		<TaskRow>
			<Card
				actions={[
					<Button key="import-btn" type="primary" onClick={handleSubmit}>
						Submit
					</Button>,
				]}
			>	
				<Form form={form} layout="vertical">
					<Form.Item<CreateEPGPramsFieldType> name="uploadUUID"  label="Upload UUID" hidden={true}  rules={[{ required: true}]}>
						<Input />
					</Form.Item>
					<Form.Item<CreateEPGPramsFieldType> name="channelName" label="Channel Name" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				</Form>
				<StyledDragger {...uploadProps}>
					<p className="ant-upload-drag-icon"> <InboxOutlined /></p>
					<p className="ant-upload-text">Click or drag files to this area to upload</p>
					<p>Support for a single or bulk upload. Must be CSVs only.</p>					
					<div className="ant-upload-hint" style={{ textAlign: "left" }}>
						<p><strong>Header style 1:</strong></p>
						<p>DATE,HOUR,Framed Duration,Segment ID,ASSET ID,TYPE,MEDIA TYPE,TITLE,Program,Duration</p>
						<p>02-24-2025,6:00:00:00 AM,00:03:00:00,1,Nosey_Ad-Slate_keepwatching_3Min,media,primary,Nosey_Ad-Slate_keepwatching_3Min,Nosey,180</p>
						<p><strong>Header style 2:</strong></p>
						<p>DATE,HOUR,DURATION,Segment ID,ASSET ID,TYPE,MEDIA TYPE,TITLE,Program,Duration (seconds)</p>
						<p>02-24-2025,6:00:00:00 AM,00:03:00:00,1,Nosey_Ad-Slate_keepwatching_3Min,media,primary,Nosey_Ad-Slate_keepwatching_3Min,Nosey,180</p>
						<ul>
							<li>Date values must be: MM-DD-YYYY format</li>
							<li>File names: Last part of input filenames are expected to be a date string prefixed by underscore. example: _02-24-2025</li>
							<li>i.e starting with underscore and a MM-DD-YYYY formatted date. Example: February 2025-AmagiNoseyOTA-Weekof_02-24-2025.csv</li>
							<li>This date is used to determine the order of file processing.</li>
						</ul>
					</div>
				</StyledDragger>
				
			</Card>
		</TaskRow>
	</StledSpin>
	);
};

export default EPGInputsUploadComponent
