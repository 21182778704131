import React from "react";
import { CustomModal, ModalTitleWithLastModifiedDate } from "../../utils/StyledComponents";
import { PromoFormProps } from "../promos";
import { Form, Input, Spin } from "antd";
import { getDurationOnly } from "../../utils";

const { Item } = Form;

const AddEditPromoModal: React.FC<PromoFormProps> = ({
	form,
	loading,
	promoEditId,
	onOkPromo,
	onCancelPromo,
}) => {
	return (
		<CustomModal
			centered
			open={true}
			onCancel={onCancelPromo}
			onOk={onOkPromo}
			title={
				promoEditId ? (
					<ModalTitleWithLastModifiedDate
						title="Update Promo Info..."
						lastModifiedDate={form.getFieldValue("updated_at")}
					/>
				) : (
					"Add Promo"
				)
			}
			okText={promoEditId ? "Update" : "Create"}
			okButtonProps={{ loading }}
			cancelButtonProps={{ disabled: loading }}
			maskClosable={false}
		>
			<Spin spinning={loading}>
				<Form form={form} layout="vertical" requiredMark={false}>
					<Item
						name="ref_id"
						label="Ref ID"
						rules={[{ required: true, message: "This is required" }]}
					>
						<Input placeholder="Episode Ref ID" />
					</Item>
					<Item
						name="promo_url"
						label="Promo URL"
						rules={[{ required: true, message: "This is required" }]}
					>
						<Input placeholder="Promo URL Link" />
					</Item>
					<Item
						label="Duration"
						name="duration"
						rules={[{ required: true, message: "This is required" }]}
					>
						<Input
							placeholder="Duration"
							maxLength={12}
							onChange={e => form.setFieldValue("duration", getDurationOnly(e.target.value))}
						/>
					</Item>
					<Item name="amagi_id" label="Amgai ID">
						<Input placeholder="Amgai ID" />
					</Item>
				</Form>
			</Spin>
		</CustomModal>
	);
};

export default AddEditPromoModal;
